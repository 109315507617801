import React, { useState } from "react";
import styled from "styled-components";

export const InformationAccordion = () => {
  const [openItem, setOpenItem] = useState<number | null>(null); // Tracks the currently open accordion item

  const items = [
    {
      id: 1,
      title: "Billing reference",
      content:
        "Billing Reference is a unique identifier assigned to a specific transaction. This is the tranaction reference from the eTax platorm for the transaction",
    },
    {
      id: 2,
      title: "Agency name",
      content:
        "Agency Name refers to the name of a government body, institution, or tax office overseeing specific taxes",
    },
    {
      id: 3,
      title: "Revenue name",
      content:
        "Revenue Name refers to the specific type of tax, levy collected by the government agency",
    },
    {
      id: 4,
      title: "Payer's name",
      content: "This is the name of the person or entity making the payment",
    },
    {
      id: 5,
      title: "Amount due",
      content: "This is the total amount of money that is owed",
    },
  ];

  const handleItemClick = (id: number) => {
    // Close the item if it's already open, otherwise open it
    setOpenItem((prevOpenItem) => (prevOpenItem === id ? null : id));
  };
  return (
    <Container>
      <Title>Assistive information</Title>
      <Subtitle>
        All you need to know about every data collected on this page.
      </Subtitle>
      {items.map((item) => (
        <AccordionWrapper key={item.id}>
          <AccordionButton onClick={() => handleItemClick(item.id)}>
            {item.title}
          </AccordionButton>
          {openItem === item.id && (
            <AccordionContent>{item.content}</AccordionContent>
          )}
        </AccordionWrapper>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  height: fit-content;
  max-width: 380px;
  flex-direction: column;
  flex: 1;
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  padding: 16px;
  margin-left: auto;
  @media (max-width: 768px) {
    display: none;
  }
`;
const Title = styled.h3`
  margin-bottom: 4px;
  font-weight: 700;
  font-size: 16px;
  p {
    font-size: 1.5rem;
  }
`;

const Subtitle = styled.p`
  margin-bottom: 16px;
  font-size: 14px;
  color: #6b6b6b;
  line-height: 19px;
`;

const AccordionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 4px;
  border-bottom: 1px solid #e4e4e4;
  &:last-child {
    border-bottom: none;
  }
`;

const AccordionButton = styled.button`
  width: 100%;
  padding: 1rem 0;
  text-align: left;
  background-color: white;
  border: none;
  cursor: pointer;
  font-size: 14px;
`;

const AccordionContent = styled.div`
  padding: 0.5rem 0;
  font-size: 14px;
`;
