import { useCallback, useState } from "react";
import RevPayRepository, {
  ValidateReferenceResponse,
} from "../repository/RevpayRepository";

export const useValidateReference = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ValidateReferenceResponse | null>(null);
  const [error, setError] = useState(false);

  const validateReference = useCallback(async (guid: string) => {
    setError(false);
    if (!guid) {
      setError(true);
      return;
    }
    try {
      setLoading(true);
      const res = await RevPayRepository.validate(guid);
      if (res.status === 200) {
        setData(res.data);
      } else {
        setError(true);
      }
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    loading,
    validateReference,
    referenceDetails: data,
    error,
    setError,
  };
};
