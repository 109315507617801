import React, { useMemo } from "react";
import styled from "styled-components";

import { PageView } from "../../components/Layout";
import { ReactComponent as SuccessIcon } from "../../../assets/icons/success-icon.svg";
import { ReactComponent as WarningIcon } from "../../../assets/icons/warning-icon.svg";
import { ReactComponent as ErrorIcon } from "../../../assets/icons/error-icon.svg";
import {
  AppDisplayMode,
  PaymentParams,
  PaymentResponseFeedback,
} from "./store/paymentProperties/types";
import FailedPayment from "../../components/FailedPayment";
import { getTransactionStatus } from "../util/PaymentUtil";
import translate from "../../../translations/translate";
import { FormattedMessage } from "react-intl";

const Container = styled(PageView)`
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const IconContainer = styled.div`
  margin-bottom: 10px;

  svg {
    width: 32px;
    height: 32px;
  }
`;

const TitleContainer = styled.div`
  margin-bottom: 10px;

  h6 {
    font-size: 2rem;
  }
`;

const Message = styled.p`
  text-align: center;
  font-size: 1.5rem;
`;

interface Props {
  appDisplayMode: AppDisplayMode;
  paymentParams: PaymentParams;
  transactionResponse: any;
}

export default function PaymentCompleteView(props: Props) {
  const { appDisplayMode, paymentParams, transactionResponse } = props;

  const { merchantName, siteRedirectUrl } = paymentParams;

  const transactionStatus = getTransactionStatus(
    transactionResponse.responseCode
  );

  const paymentResponseFeedbacks: PaymentResponseFeedback[] = useMemo(() => {
    const doesRedirectExist = siteRedirectUrl && siteRedirectUrl.length > 0;

    return [
      {
        status: "CANCELLED",
        description: `The payment could not be completed. ${
          doesRedirectExist
            ? `You will now be redirected to <b>${merchantName}</b>`
            : "You may close this page"
        }`,
      },
      {
        status: "EXPIRED",
        description: `Your transaction was not processed. ${
          doesRedirectExist
            ? `You will now be redirected to <b>${merchantName}<b> <br />`
            : "You may close this page"
        }`,
      },
      {
        status: "FAILURE",
        description:
          "The payment could not be completed with the selected payment method. Please choose an alternative method or try again.",
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentParams]);

  const paymentResponseFeedback = paymentResponseFeedbacks.find(
    (item) => item.status === transactionStatus.status
  ) as PaymentResponseFeedback;

  const getTransactionStatusTitle = useMemo(() => {
    switch (transactionStatus.status) {
      case "SUCCESS":
        return `${translate("paymentSuccessful", "Payment Successful")}`;
      case "IN_PROGRESS":
        return `${translate(
          "transactionInProgress",
          "Transaction in Progress"
        )}`;
      case "EXPIRED":
        return `${translate("transactionExpired", "Transaction Expired")}`;
      case "CANCELLED":
        return `${translate("paymentCancelled", "Payment Cancelled")}`;
      case "FAILURE":
        return `${translate("paymentFailed", "Payment Failed")}`;

      default:
        break;
    }
  }, [transactionStatus]);

  return (
    <Container>
      {transactionStatus.isFailed ? (
        <FailedPayment
          icon={
            <IconContainer>
              {transactionStatus.status === "CANCELLED" && <WarningIcon />}
              {transactionStatus.status === "EXPIRED" && <ErrorIcon />}
              {transactionStatus.status === "FAILURE" && <ErrorIcon />}
            </IconContainer>
          }
          title={getTransactionStatusTitle}
          transactionStatus={transactionStatus.status}
          paymentResponseFeedback={paymentResponseFeedback}
        />
      ) : (
        <>
          <IconContainer>
            {transactionStatus.isSuccessful && <SuccessIcon />}
          </IconContainer>
          <IconContainer>
            {transactionStatus.isPending && <WarningIcon />}
          </IconContainer>

          <TitleContainer>
            {/* <h6>
              <FormattedMessage id="paymentSuccessful" defaultMessage="Payment Successful" />
            </h6> */}
            <h6>{getTransactionStatusTitle}</h6>
          </TitleContainer>
          {appDisplayMode === "REDIRECT" && (
            <Message>
              <FormattedMessage
                id="beingRedirectedTo"
                defaultMessage="You’re being redirected to "
              />{" "}
              <b>{merchantName}</b>, <br />
              <FormattedMessage
                id="pleaseDoNotClose"
                defaultMessage="please do not close this page."
              />
            </Message>
          )}

          {appDisplayMode === "INLINE" && (
            <Message>
              {siteRedirectUrl ? (
                <>
                  We are taking you back to <b>{merchantName}</b>, <br />
                  please do not close this page.
                </>
              ) : (
                <>Your transaction has been completed successfully</>
              )}
            </Message>
          )}
        </>
      )}
    </Container>
  );
}
