type DateTimeFormatOptions = {
  weekday: "short";
  month: "short";
  day: "numeric";
  year: "numeric";
  hour: "numeric";
  minute: "numeric";
  second: "numeric";
  hour12: boolean;
};

export function getCurrentDateFormatted() {
  const now = new Date();
  const options: DateTimeFormatOptions = {
    weekday: "short", // Short weekday name (e.g., Mon)
    month: "short", // Short month name (e.g., Dec)
    day: "numeric", // Day of the month (e.g., 2)
    year: "numeric", // Four-digit year (e.g., 2024)
    hour: "numeric", // Hour (e.g., 10)
    minute: "numeric", // Minute (e.g., 23)
    second: "numeric", // Second (e.g., 09)
    hour12: true, // Use 12-hour format (AM/PM)
  };

  return now.toLocaleDateString("en-US", options); //remove commas
}
