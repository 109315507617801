export const SERVICE_BASE_URL = WEBPAY_UI_ENV_SERVICE_BASE_URL;
export const PAYMENT_BASE_URL = WEBPAY_UI_ENV_PAYMENT_BASE_URL;
export const OTP_REQUEST_INTERVAL_SEC = 60;
export const DEFAULT_ERROR_MESSAGE = "Something went wrong. Please try again."
export const PAY_WITH_APP_TOKEN_AUTH = {
  username: "WEBPAY_UI_ENV_PAY_WITH_APP_TOKEN_USERNAME",
  password: "WEBPAY_UI_ENV_PAY_WITH_APP_TOKEN_PASSWORD",
};
export const ACQUIRED_BY_URL = ACQUIRED_BY_SERVICE_BASE_URL;
export const REVPAY_MERCHANT_CODE = REVPAY_ENV_MERCHANT_CODE;
export const REVPAY_PAYITEMID = REVPAY_ENV_PAYITEM_ID;
