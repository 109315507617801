import React from "react";
import styled from "styled-components";
import AppLogo from "../../../../../assets/images/interswitch-logo.png";

export const Footer = () => {
  return (
    <Wrapper>
      <p>powered by</p>
      <img src={AppLogo} alt="Interswitch logo" />
    </Wrapper>
  );
};

const Wrapper = styled("div")`
  display: flex;
  justify-content: center;
  // align-items: center;
  padding: 20px 60px;
  background-color: white;
  margin-top: auto;
  p {
    font-size: 12px;
    color: #626d7c;
  }
  img {
    height: 20px;
    margin-left: 10px;
  }
`;
