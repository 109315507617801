import styled from "styled-components";

export const Container = styled.form`
  width: 50%;
  max-width: 600px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    width: 90%;
  }
`;

export const Title = styled.h1`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 16px;
`;

export const DetailsWrapper = styled.div`
  display: flex;
  width: 90%;
  flex-direction: column;
  align-items: center;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin-bottom: 16px;
  padding: 16px;
`;

export const TimeSpan = styled.p`
  font-size: 14px;
  width: 100%;
  text-align: center;
  color: #626d7c;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
`;

export const PayerName = styled.p`
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  color: black;
  padding: 8px 0;
`;

export const Amount = styled.p`
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: #626d7c;
  padding: 8px 0;
`;

export const Divider = styled.div<{ width?: string }>`
  height: 0;
  width: ${({ width }) => width || "100%"};
  border-bottom: solid 1px rgba(21, 42, 56, 0.1);
  margin: 1.3rem 0;
`;

export const BillingReferenceBlock = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 8px 0;
  @media (max-width: 480px) {
    text-align: center;
  }

  .header {
    color: #626d7c;
    font-size: 14px;
  }
  .value {
    font-weight: 800;
    font-size: 16px;
    margin-top: 4px;
  }
`;

export const CopyButton = styled.button`
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 8px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 480px) {
    display: none;
  }
`;
