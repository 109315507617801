import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import usePrevious from '../../../../../hooks/usePrevious';
import { AppState } from '../../../../../store/RootReducer';
import { PAYMENT__CARD_CARDINAL, PAYMENT__CARD_OTP } from '../../../../Routes';
import { MakePaymentRequest, MakePaymentResponse } from '../../../domain/repositories/CardRepository';
import { PaymentChannelType, PaymentParams } from '../../../../payment/presentation/store/paymentProperties/types';
import { completeTransaction } from '../../../../payment/presentation/store/paymentStatus/actions';
import { getIsCardPinValid } from '../../../utils/fieldValidators';
import { generateSecureData } from '../../../utils/secureGenerator';
import { setCachedCardDetails } from '../../store/cachedCardDetails/actions';
import { CachedCardParams } from '../../store/cachedCardDetails/types';
import { triggerMakePayment } from '../../store/makePayment/actions';

import { PageView, PageTitle, PageSubTitle, FieldErrorText } from '../../../../components/Layout';
import BackControl from '../../../../components/BackControl';
import { Button } from '../../../../components/Button';
import PinInputField from '../../../../components/PinInputField';
import { triggerShowNotification } from '../../../../components/NotificationWidget/store/actions';
import { TriggerShowNotificationPayload } from '../../../../components/NotificationWidget/store/types';
import { WalletUserData } from '../../../../wallet/domain/repositories/WalletRepository';
import { PaymentUtil } from '../../../../payment/util/PaymentUtil';
import { getResponseCodeDescription } from '../../../utils/CardUtils';
import { AxiosError } from 'axios';
import { getAxiosErrorData } from '../../../../../core/api/helpers';
import translate from '../../../../../translations/translate';
import { FormattedMessage } from 'react-intl';
import getCardinalDeviceInformation from '../../../utils/deviceInformation';


const Container = styled(PageView)`
  display: flex;
  flex-direction: column;
`;

const Form = styled.form`
  width: 100%;
`;

const PinFieldContainer = styled.div`
  margin-bottom: 15px;
`;


interface StoreStateProps {
  paymentParams: PaymentParams;
  currentPaymentChannel: PaymentChannelType;
  cachedCardParams: CachedCardParams;
  userData: WalletUserData | null;

  makePaymentPending: boolean;
  makePaymentError: AxiosError | null;
  makePaymentResponse: MakePaymentResponse;
}

interface StoreDispatchProps {
  showNotification: (payload: TriggerShowNotificationPayload) => void;
  setCachedCardDetails: (cardParams: CachedCardParams) => void;
  completeTransaction: (transactionResponse: any) => void;
  makePayment: (request: MakePaymentRequest) => void;

}

interface OwnProps {

}

type Props = StoreStateProps & StoreDispatchProps & OwnProps;

export function CardPinPage(props: Props) {
  const {
    paymentParams,
    currentPaymentChannel,
    cachedCardParams,
    userData,

    makePaymentPending,
    makePaymentError,
    makePaymentResponse,

    showNotification,
    setCachedCardDetails,
    completeTransaction,
    makePayment,
  } = props;

  const history = useHistory();

  const prevMakePaymentPending = usePrevious(makePaymentPending);

  const [showFormErrors, setShowFormErrors] = useState(false);
  const [routedMakePaymentPayload, setRoutedMakePaymentPayload] = useState<MakePaymentRequest>();

  const [pinValue, setPinValue] = useState('');

  const backControlHandler = () => {
    history.goBack();
  }

  const {
    isValid: isCardPinValid,
    message: cardPinErrorMessage
  } = getIsCardPinValid(pinValue);

  const authorizeButtonHandler = () => {
    setShowFormErrors(true);

    if (!isCardPinValid) return;

    // Cache card details
    setCachedCardDetails({
      ...cachedCardParams,
      pin: pinValue,
    });

    const { secure, pinBlock } = generateSecureData(
      cachedCardParams.cardNumber,
      cachedCardParams.cardExpiry,
      cachedCardParams.cardCvv,
      pinValue
    );

    const { paymentMethod } = cachedCardParams;

    if (currentPaymentChannel === "WALLET" && paymentMethod) {

      if (!userData) return;

      const { mobileNo } = userData;
      const { name: walletCardName, cardIdentifier, walletInstrumentIdentifier } = paymentMethod;

      makePayment({
        secureData: secure,
        pinBlock: pinBlock,
        merchantCode: paymentParams.merchantCode,
        payableCode: paymentParams.payableCode,
        paymentId: paymentParams.paymentId,
        instrumentIdentifier: cardIdentifier,
        walletInfo: {
          walletId: mobileNo,
          walletCardName: walletCardName,
          walletIdentifier: walletInstrumentIdentifier,
        },
        deviceInformation: getCardinalDeviceInformation(),
      });
      return;
    }

    const routedMakePaymentPayload: MakePaymentRequest = {
      secureData: secure,
      pinBlock: pinBlock,
      merchantCode: paymentParams.merchantCode,
      payableCode: paymentParams.payableCode,
      paymentId: paymentParams.paymentId,
      googlePayToken: cachedCardParams.googlePayToken,
      deviceInformation: getCardinalDeviceInformation(),
    }

    setRoutedMakePaymentPayload(routedMakePaymentPayload);

    makePayment(routedMakePaymentPayload);
  }

  useEffect(() => {
    if (!(!makePaymentPending && prevMakePaymentPending)) return;

    if (makePaymentError) {
      const responseData = getAxiosErrorData(makePaymentError);
      
      if (!responseData) {
        showNotification({ type: 'ERROR' });
        return;
      } 

      const { responseCode } = responseData;

      if (PaymentUtil.isTransactionComplete(responseCode)) {
        completeTransaction(responseData);
        return;
      }

      showNotification({ 
        type: 'ERROR', 
        message: getResponseCodeDescription(responseCode)
      });

      // Abort payment
      completeTransaction({
        responseCode,
        merchantTransactionReference:
          paymentParams.merchantTransactionReference,
      });
      return;
    }

    const { responseCode, requiresCentinelAuthorization, cardinalInfo } = makePaymentResponse;

    if (PaymentUtil.isTransactionComplete(responseCode)) {
      completeTransaction(makePaymentResponse);
      return;
    }

    if (responseCode === 'T0' && !requiresCentinelAuthorization) {
      history.replace(PAYMENT__CARD_OTP);
      return;
    }

    // cardinalInfo means its a 3Ds payment
    if (cardinalInfo) {
      history.push({
        pathname: PAYMENT__CARD_CARDINAL,
        state: {
          cardinalAuthenticationInformation: cardinalInfo,
          routedMakePaymentResponse: makePaymentResponse,
          routedMakePaymentPayload,
        },
      });
      return;
    }
    
    showNotification({
      type: 'ERROR', 
      message: getResponseCodeDescription(responseCode),
    });

    // Abort payment
    completeTransaction({
      responseCode,
      merchantTransactionReference:
        paymentParams.merchantTransactionReference,
    });

    backControlHandler();
  }, [makePaymentPending]);

  let paymentProps = window.iswPaymentProps;
  const { acquiredBy } = paymentProps;

  return (
    <Container>
      <BackControl
        text={translate('back', 'Back')}
        onClick={backControlHandler}
      />

      <PageTitle>
        <FormattedMessage id="enterPin" defaultMessage="Enter PIN" />
      </PageTitle>
      <PageSubTitle>
        <FormattedMessage id="enter4DigitPin" defaultMessage="Enter your 4-digit card PIN to complete this payment." />
      </PageSubTitle>

      <Form>
        <PinFieldContainer>
          <PinInputField
            value={pinValue}
            onValueChange={(value) => setPinValue(value)}
            lengthLimit={4}
            error={showFormErrors && !isCardPinValid}
          />

          {showFormErrors && !isCardPinValid && (
            <FieldErrorText>{cardPinErrorMessage}</FieldErrorText>
          )}
        </PinFieldContainer>

        <Button
          text={translate('authorize', 'Authorize')}
          color={acquiredBy === 'ZIB' ? "DANGER" : "PRIMARY"}
          loading={makePaymentPending}
          containerStyle={{ width: '100%' }}
          onClick={authorizeButtonHandler}
        />
      </Form>

    </Container>
  );
}


const mapStateToProps = (state: AppState): StoreStateProps => ({
  paymentParams: state.payment.paymentProperties.paymentParams as PaymentParams,
  currentPaymentChannel: state.payment.currentPaymentChannel as PaymentChannelType,
  cachedCardParams: state.card.cachedCardDetails.cachedCardParams as CachedCardParams,
  userData: state.wallet.userWalletData.userData,

  makePaymentPending: state.card.makePayment.makePaymentPending,
  makePaymentError: state.card.makePayment.makePaymentError,
  makePaymentResponse: state.card.makePayment.makePaymentResponse as MakePaymentResponse
});

const mapDispatchToProps = (
  dispatch: (action: any) => void
): StoreDispatchProps => ({
  showNotification(payload: TriggerShowNotificationPayload) {
    dispatch(triggerShowNotification(payload))
  },
  completeTransaction(transactionResponse: any) {
    dispatch(completeTransaction(transactionResponse));
  },
  setCachedCardDetails(cardParams: CachedCardParams) {
    dispatch(setCachedCardDetails(cardParams));
  },
  makePayment(request: MakePaymentRequest) {
    dispatch(triggerMakePayment(request));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CardPinPage);