import * as React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { PageView } from '../../../components/Layout';
import { PAYMENT_METHODS__ROOT } from '../../../Routes';
import BackControl from '../../../components/BackControl';
import PaymentForm from './components/PaymentForm';
import ErrorView from '../../../components/ErrorView';
import Loader from '../../../components/Loader';
import useGetProviders from './hooks/useGetProviders';
import translate from '../../../../translations/translate';
import { FormattedMessage } from 'react-intl';

export default function DrcMobileMoney() {
	const history = useHistory();

	const goBack = () => {
		history.push(PAYMENT_METHODS__ROOT);
	};

	const { loading, getProviders, data, error } = useGetProviders();

	return (
		<Container>
			<BackControl text={translate('changePaymentMethod', 'Change payment method')} onClick={goBack} />

			<div className="header">
				<h4>
				<FormattedMessage id="payWithMobileMoney" defaultMessage="Pay with Mobile Money" />
				</h4>
				<p>
				<FormattedMessage id="enterYourMobileNo" defaultMessage="Enter your mobile money details below to continue" />
				</p>
			</div>

			{loading && (
				<div className="loader-box">
					<Loader />
				</div>
			)}

			{error && <ErrorView action={getProviders} />}

			{data && <PaymentForm billers={data?.data} />}
		</Container>
	);
}

const Container = styled(PageView)`
	.header {
		h4 {
			font-size: 1.7rem;
			margin-bottom: 2px;
			color: #152a38;
		}
		p {
			font-size: 1.2rem;
			color: #616161;
		}
	}

	.loader-box {
		height: 200px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
`;

const Message = styled.p`
	text-align: center;
`;
