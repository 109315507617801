const DEV = process.env.NODE_ENV === "development";

const ISWK8 = window.location.origin.includes("k8.isw.la");

const ISWK9 = window.location.origin.includes("k9.isw.la");

const UAT = window.location.origin.includes("qa.interswitchng.com");

export const ISWPROD = !ISWK8 && !ISWK9 && !UAT && !DEV;

export const defaultEnabledChannelsMap = {
  CARD: true,
  WALLET: false,
  TRANSFER: false,
  OPAY: false,
  QR: false,
  USSD: false,
  PAY_WITH_APP: false,
  CUSTOMER_WALLET: false,
  CRD: false,
  GOOGLE_PAY: false,
  UGANDA_MOBILE_MONEY: false,
  DRC_MOBILE_MONEY: false,
};

export const nigerianCurrencyCode = "566";
