import 'react-app-polyfill/stable';
import React, { useState } from 'react';
import { getStore } from './store';
import { Provider } from 'react-redux';
import { MemoryRouter } from 'react-router-dom';
import { RootView } from './features/RootView';
import { PAYMENT_METHODS__ROOT, REVPAY__ROOT, REVPAY__SUCCESS } from './features/Routes';
import { GlobalStyle } from './styling/global';
import './styling/fonts.css';
import testPaymentProps from "./test/testPaymentProps";
import { IntlProvider } from 'react-intl';
import { messages } from './translations/messages';
import { TranslationContext } from './contexts/TranslationContext';
import { initCybersourceFingerprint } from './cybersource';


function App() {
	const store = getStore();
	const [lang, setLang] = useState('en');
	const memoizedLang = React.useMemo(() => ({ lang, setLang }), [lang]);

	let entries: string[] = [PAYMENT_METHODS__ROOT];

const history = require("history").createBrowserHistory();
const isRevpay = history.location.pathname === "/revpay";
const isRevpaySuccess = history.location.pathname === "/revpay-success";

if (isRevpay) {
  entries = [REVPAY__ROOT + history.location.search];
} else if (isRevpaySuccess) {
  entries = [REVPAY__SUCCESS];
}

if (!(window as any).iswPaymentProps) {
  (window as any).iswPaymentProps = testPaymentProps;
}

initCybersourceFingerprint(window.iswPaymentProps);

	return (
		<>
			<GlobalStyle />
			<Provider store={store}>
				<TranslationContext.Provider value={memoizedLang}>
					<IntlProvider locale={lang} messages={messages[lang]}>
						<MemoryRouter
							initialEntries={entries}
							initialIndex={0}
						>
							<RootView />
						</MemoryRouter>
					</IntlProvider>
				</TranslationContext.Provider>
			</Provider>
		</>
	);
}
export default App;
