import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import BackControl from "../../../../components/BackControl";
import { PageTitle, PageSubTitle } from "../../../../components/Layout";

import LoadingView from "../../../../components/LoadingView";
import ErrorView from "../../../../components/ErrorView";

import { AppState } from "../../../../../store/RootReducer";
import { PaymentParams } from "../../../../payment/presentation/store/paymentProperties/types";
import { connect } from "react-redux";
import palmpayRepository, {
  InitializeRequest,
  InitializeResponse,
} from "../../../domain/repositories/PalmpayRepository";
import { triggerShowNotification } from "../../../../components/NotificationWidget/store/actions";
import { triggerInitialize } from "../../store/initialize/actions";
import { TriggerShowNotificationPayload } from "../../../../components/NotificationWidget/store/types";

import { asyncDelay } from "../../../../../core/util/asyncUtil";
import { completeTransaction } from "../../../../payment/presentation/store/paymentStatus/actions";
import { PaymentUtil } from "../../../../payment/util/PaymentUtil";
import useCountdownTimer from "../../../../../hooks/useCountdownTimer";
import { getFormattedInterval } from "../../../../../util/timeUtil";
import CopyWidget from "../../../../components/CopyWidget";
import { ReactComponent as InfoIcon } from "../../../../../assets/icons/info.svg";
import {
  CodeContainer,
  CodeDescription,
  CodeHeader,
  CodeValue,
  Container,
  InstructionContainer,
  InstructionContentContainer,
  InstuctionHeader,
} from "./styles";
import { differenceInMilliseconds } from "date-fns";

const CODE_VALIDITY_PERIOD_MS = 60 * 5 * 1000;

interface StoreStateProps {
  paymentParams: PaymentParams;

  initializePending: boolean;
  initializeError: boolean;
  initializeResponse: InitializeResponse | null;
  initializeDate: Date | null;
}

interface StoreDispatchProps {
  showNotification: (payload: TriggerShowNotificationPayload) => void;
  completeTransaction: (transactionResponse: any) => void;
  initialize: (request: InitializeRequest) => void;
}

interface OwnProps {}

type Props = StoreStateProps & StoreDispatchProps & OwnProps;

export function RootPage(props: Props) {
  const {
    paymentParams,

    initializePending,
    initializeError,
    initializeResponse,
    initializeDate,
    showNotification,
    completeTransaction,
    initialize,
  } = props;
  const history = useHistory();

  const isPageMounted = useRef(true);

  const { time: codeExpiryTime, restart: restartCodeExpiry } =
    useCountdownTimer({ timeout: CODE_VALIDITY_PERIOD_MS }, false);

  const navigateToPaymentMethods = () => {
    history.goBack();
  };

  const [transactionProcessingFailed, setTransactionProcessingFailed] =
    useState(false);

  const pollTransactionStatus = async () => {
    while (true) {
      if (
        !isPageMounted.current ||
        !initializeResponse ||
        transactionProcessingFailed
      )
        return;

      const { transactionReference } = initializeResponse;

      let getStatusResponse;

      try {
        getStatusResponse = await palmpayRepository.getStatus({
          transactionReference: transactionReference,
          merchantCode: paymentParams.merchantCode,
        });
      } catch (err) {
        await asyncDelay(2000);
        continue;
      }

      const { responseCode } = getStatusResponse;

      if (responseCode === "01") {
        setTransactionProcessingFailed(true);
        break;
      }

      if (responseCode === "09") {
        await asyncDelay(2000);
        continue;
      }

      if (PaymentUtil.isTransactionSuccessful(responseCode)) {
        completeTransaction(getStatusResponse);
        break;
      }

      await asyncDelay(2000);
    }
  };

  const callInitialize = () => {
    const {
      merchantCode,
      payableCode,
      paymentId,
      merchantTransactionReference,
    } = paymentParams;

    initialize({
      merchantCode,
      payableCode,
      transactionReference: merchantTransactionReference,
      paymentId,
      currencyCode: paymentParams.currencyCode,
    });
  };

  const startTimer = (initializeDate: Date | null) => {
    const timeLeftMs =
      CODE_VALIDITY_PERIOD_MS -
      differenceInMilliseconds(new Date(), initializeDate || new Date());

    restartCodeExpiry(timeLeftMs);
  };

  useEffect(() => {
    return () => {
      isPageMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (initializePending || !!initializeResponse) return;
    callInitialize();
  }, []);

  useEffect(() => {
    if (!initializeResponse) return;

    const { responseCode } = initializeResponse;

    if (responseCode === "01") {
      setTransactionProcessingFailed(true);
      return;
    }

    pollTransactionStatus();
    startTimer(initializeDate);
  }, [initializeResponse]);

  useEffect(() => {
    const { minutes, seconds } = codeExpiryTime;

    if (minutes === 0 && seconds <= 0) {
      setTransactionProcessingFailed(true);
    }
  }, [codeExpiryTime]);
  
  useEffect(() => {
    if (transactionProcessingFailed) {
      // Abort payment
      completeTransaction({
        responseCode: "",
        merchantTransactionReference:
          paymentParams.merchantTransactionReference,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initializeResponse]);

  let spacedCode = "";
  if (initializeResponse) {
    const { payCode } = initializeResponse;
    const midpoint = payCode.length / 2;

    spacedCode = `${payCode.slice(0, midpoint)} ${payCode.slice(midpoint)}`;
  }

  return (
    <Container>
      <BackControl text="Back" onClick={navigateToPaymentMethods} />

      {initializePending && <LoadingView />}

      {!initializePending &&
        initializeResponse !== null &&
        !transactionProcessingFailed && (
          <>
            <PageTitle>Pay with PalmPay App</PageTitle>
            <PageSubTitle>
              Copy the code below and paste it into your PalmPay app to complete
              this payment
            </PageSubTitle>

            <CodeContainer>
              <CodeHeader>
                <CodeValue>{spacedCode}</CodeValue>
                <CopyWidget text={"fdfd"} />
              </CodeHeader>

              <CodeDescription>
                This code expires in{" "}
                <b>
                  {getFormattedInterval(codeExpiryTime.minutes)}:
                  {getFormattedInterval(codeExpiryTime.seconds)}
                </b>{" "}
                and is valid for
                <br />
                this transaction only
              </CodeDescription>
            </CodeContainer>

            <InstructionContainer>
              <InfoIcon width={16} height={16} />

              <InstructionContentContainer>
                <InstuctionHeader>How to use this code</InstuctionHeader>
                <ul>
                  <li>1. Open the PalmPay mobile app</li>
                  <li>2. Select PayCode</li>
                  <li>3. Paste the code above into the PalmPay code area</li>
                  <li>
                    4. Click on the <b>Pay Now</b> button
                  </li>
                </ul>
              </InstructionContentContainer>
            </InstructionContainer>
          </>
        )}
    </Container>
  );
}

const mapStateToProps = (state: AppState): StoreStateProps => ({
  paymentParams: state.payment.paymentProperties.paymentParams as PaymentParams,

  initializePending: state.palmpay.initialize.initializePending,
  initializeError: state.palmpay.initialize.initializeError,
  initializeResponse: state.palmpay.initialize.initializeResponse,
  initializeDate: state.palmpay.initialize.initializeDate,
});

const mapDispatchToProps = (
  dispatch: (action: any) => void
): StoreDispatchProps => ({
  showNotification(payload: TriggerShowNotificationPayload) {
    dispatch(triggerShowNotification(payload));
  },
  completeTransaction(transactionResponse: any) {
    dispatch(completeTransaction(transactionResponse));
  },
  initialize(request: InitializeRequest) {
    dispatch(triggerInitialize(request));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RootPage);
