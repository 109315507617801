import {
  INITIALIZE_COMPLETE,
  PaymentPropertiesState,
  PaymentPropertiesActionType,
  SET_TUC_STATUS,
  SET_ESCROW_STATUS,
} from "./types";

const initialState: PaymentPropertiesState = {
  initialized: false,
  displayMode: "REDIRECT",
  paymentParams: null,
  paymentChannels: null,
};

export function PaymentPropertiesReducer(
  state: PaymentPropertiesState = initialState,
  action: PaymentPropertiesActionType
): PaymentPropertiesState {
  switch (action.type) {
    case SET_TUC_STATUS: {
      const { payload } = action;
      return {
        ...state,
        tucAccepted: payload,
      };
    }

    case SET_ESCROW_STATUS: {
      const { payload } = action;
      return {
        ...state,
        escrowAccepted: payload,
      };
    }

    case INITIALIZE_COMPLETE: {
      const { payload } = action;

      const { displayMode, paymentParams, paymentChannels } = payload;

      return {
        ...state,
        initialized: true,
        displayMode,
        paymentParams,
        paymentChannels,
      };
    }

    default: {
      return state;
    }
  }
}
