import { AxiosError } from "axios";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { DEFAULT_ERROR_MESSAGE } from "../../../../../config/properties";
import { getAxiosErrorData } from "../../../../../core/api/helpers";
import useIsMountedRef from "../../../../../hooks/useIsMountedRef";
import { AppState } from "../../../../../store/RootReducer";
import {
  GetBinConfigurationResponse,
  MakePaymentRequest,
  MakePaymentResponse,
} from "../../../../card/domain/repositories/CardRepository";
import { setCachedCardDetails } from "../../../../card/presentation/store/cachedCardDetails/actions";

import { generateSecureData } from "../../../../card/utils/secureGenerator";
import { showNotification } from "../../../../components/NotificationWidget/store/actions";
import RequestState from "../../../../payment/domain/RequestState";
import { completeTransaction } from "../../../../payment/presentation/store/paymentStatus/actions";
import { PaymentUtil } from "../../../../payment/util/PaymentUtil";
import { PAYMENT__CARD_CARDINAL, PAYMENT__CARD_PIN } from "../../../../Routes";
import {
  getBinConfiguration,
  makePayment,
} from "../../../domain/respositories/CardRepository";
import getCardinalDeviceInformation from "../../../../card/utils/deviceInformation";

export default function usePay() {
  const [req, setReq] = React.useState<
    RequestState<GetBinConfigurationResponse>
  >({
    loading: false,
  });

  const [payReq, setPayReq] = React.useState<RequestState<MakePaymentResponse>>(
    {
      loading: false,
    }
  );

  const paymentParams = useSelector(
    (state: AppState) => state.payment.paymentProperties.paymentParams
  );
  const dispatch = useDispatch();
  const isMounted = useIsMountedRef();
  const history = useHistory();

  const finalizePayment = async (
    payload: MakePaymentRequest & { cvv: string; exp: string; cardPan: string }
  ) => {
    const {cvv, cardPan, exp, ...data} = payload;
    
    const { secure: secureData, pinBlock } = generateSecureData(
      cardPan,
      exp,
      cvv
    );

    try {
      setPayReq({ loading: true });

      const response = await makePayment({ ...data, secureData, pinBlock });

      if (!isMounted.current) return;

      setPayReq({ data: response, loading: false });

      const { responseCode } = response;
      if (PaymentUtil.isTransactionComplete(responseCode)) {
        dispatch(completeTransaction(response));
        return;
      }

      showNotification({ type: "ERROR" });
    } catch (error) {
      if (!isMounted.current) return;

      const errorObj = getAxiosErrorData(error as AxiosError);

      const errorMessage = errorObj?.description ?? DEFAULT_ERROR_MESSAGE;

      setPayReq({ error: errorMessage, loading: false });

      if (!errorObj) {
        dispatch(showNotification({ type: "ERROR" }));
        return;
      }

      const { responseCode } = errorObj;
      if (PaymentUtil.isTransactionComplete(responseCode)) {
        dispatch(completeTransaction(errorObj));
        return;
      }

      dispatch(showNotification({ type: "ERROR" }));
    }
  };

  const pay = async (
    paymentData: google.payments.api.PaymentMethodData & {
      // exp: string;
      cvv: string;
    }
  ) => {
    const {
      cvv,
      // exp,
      info,
      tokenizationData: { token },
    } = paymentData;
    const cardPanValue = info?.cardDetails;

    if (!cardPanValue || !paymentParams) return;

    const cardPan = cardPanValue.padStart(16, "1");

    const exp = `11/${new Date().getFullYear() + (5).toString().substring(2)}`;

    const { secure: secureData, pinBlock } = generateSecureData(
      cardPan,
      exp,
      cvv
    );

    const { merchantCode, payableCode, paymentId } = paymentParams;

    try {
      setReq({ loading: true });

      const response = await getBinConfiguration({
        googlePayToken: token,
        secureData,
        pinBlock,
        merchantCode,
        payableCode,
        paymentId,
      });

      if (!isMounted.current) return;

      setReq({ data: response, loading: false });

      // Cache card details
      dispatch(
        setCachedCardDetails({
          cardNumber: cardPanValue,
          cardExpiry: exp,
          cardCvv: cvv,
          googlePayToken: token,
        })
      );

      const {
        cardTypeGatewayConfiguration,
        cardinalAuthenticationInformation,
      } = response;

      const { supportsCardinalAuthentication, supportsPin } =
        cardTypeGatewayConfiguration;

      // if card supports cardinal authentication navigate to cardinal route
      if (supportsCardinalAuthentication) {
        history.push({
          pathname: PAYMENT__CARD_CARDINAL,
          state: { cardinalAuthenticationInformation },
        });
        return;
      }

      // if card supports pin redirect to pin page
      if (supportsPin) {
        history.push(PAYMENT__CARD_PIN);
        return;
      }

      // make payment
      finalizePayment({
        googlePayToken: token,
        secureData,
        pinBlock,
        merchantCode,
        payableCode,
        paymentId,
        cardPan,
        exp,
        cvv,
        deviceInformation: getCardinalDeviceInformation(),
      });      
      
    } catch (error: any) {
      if (!isMounted.current) return;

      const errorMessage =
        getAxiosErrorData(error as AxiosError)?.description ??
        DEFAULT_ERROR_MESSAGE;

      setReq({ error: errorMessage, loading: false });

      dispatch(showNotification({ type: "ERROR" }));
    } finally {
      setReq({ loading: false });
    }
  };

  return { pay, loading: req.loading || payReq.loading };
}
