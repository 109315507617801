import { DEFAULT_ERROR_MESSAGE } from "../../../config/properties";
import translate from "../../../translations/translate";

interface ResponseCodeMapping {
  responseMessage: string;
  responseCodes: string[];
}

const responseCodeMappings: ResponseCodeMapping[] = [
  {
    responseMessage: 'Successful',
    responseCodes: ['00'],
  },
  {
    responseMessage: 'Your bank declined the transaction. Contact your bank or use a different card or payment method',
    responseCodes: ['01', '02', '04', '05', '06', '07', '12', '13', '14', '17', '21', '22', '25', '26', '33', '34',  '36' , '38', '39', '40', '41', '42', '43', '44', '45', '52', '53', '54', '55', '56', '57', '58', '59', '60', '61', '62', '63', '65', '66', '68', '75', '77', '79', '81', '88', '89', '93', '98', 'F4', 'F5', 'G3', 'G4', 'H4',],
  },
  {
    responseMessage: 'Insufficient funds. Use a different card or try another payment method',
    responseCodes: ['51'],
  },
  {
    responseMessage: translate('unableToProcessTransaction', 'We were unable to process your transaction at this time. Please try again later'),
    responseCodes: ['91', '92', '96', 'E16', 'E20', 'EM', 'Z162'],
  },
  {
    responseMessage: translate('cardNotAccepted', 'Your card is not accepted by this merchant'),
    responseCodes: ['Z8', 'Z82'],
  },
  {
    responseMessage: 'You have exceeded the maximum amount allowed. Use a different card or payment method',
    responseCodes: ['X03'],
  },
  {
    responseMessage: 'Invalid token supplied. Please try again or use a different payment method',
    responseCodes: ['Z67', 'Z74'],
  },
  {
    responseMessage: translate('unableToProcessTransaction', 'We were unable to process your transaction at this time. Please use a different payment method'),
    responseCodes: ['Z64'],
  },
];

export function getResponseCodeDescription(responseCode: string) {

  for (let i = 0; i < responseCodeMappings.length; i++) {
    const {responseMessage, responseCodes } = responseCodeMappings[i];

    if (responseCodes.indexOf(responseCode) != -1) {
      return responseMessage;
    }
  } 

  return DEFAULT_ERROR_MESSAGE;
}