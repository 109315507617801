import React from "react";
import { Route, Switch } from "react-router-dom";
import { RevPayFormView } from "./form";
import { SuccessView } from "./success";

export const RevpayRoot = () => {
  return (
    <Switch>
      <Route exact path="/revpay-success" component={SuccessView} />
      <Route exact path="/revpay" component={RevPayFormView} />
    </Switch>
  );
};
