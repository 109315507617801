import React, { useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import styled from "styled-components";

import { SERVICE_BASE_URL } from "../../config/properties";
import { triggerInitialize } from "../payment/presentation/store/paymentProperties/actions";
import { AppDisplayMode } from "../payment/presentation/store/paymentProperties/types";

import { ReactComponent as SettingsIcon } from "./settings.svg";
import { ReactComponent as CloseIcon } from "./close.svg";

const displayOptions: AppDisplayMode[] = ["INLINE", "REDIRECT"];

const Container = styled.div`
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 100;
`;

const TriggerContainer = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: white;
  border: 1px solid red;
  border-radius: 5px;
  cursor: pointer;
  opacity: 1;

  svg {
    width: 20px;
    height: 20px;
    fill: red;
  }
`;

const FormContainer = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  display: flex;
  flex-direction: column;
  padding: 15px;
  border: 1px solid black;
  background-color: white;

  label {
    display: block;
    margin-bottom: 3px;
  }
`;

const FormCloseControl = styled.div`
  align-self: flex-end;
  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;
  }
`;

const ControlContainer = styled.div`
  margin-bottom: 10px;
`;

interface StoreStateProps {}

interface StoreDispatchProps {
  initializePaymentProperties: (paymentProps: any) => void;
}

interface OwnProps {}

type Props = StoreStateProps & StoreDispatchProps & OwnProps;

export function DemoWidget(props: Props) {
  const { initializePaymentProperties } = props;

  const [isFormVisible, setIsFormVisible] = useState(false);

  const [merchantCode, setMerchantCode] = useState("MX6072");
  const [payableCode, setPayableCode] = useState("9405967"); // opay
  const [amount, setAmount] = useState("1500");
  const [currencyCode, setCurrencyCode] = useState("566");
  const [transactionReference, setTransactionReference] = useState(
    `demo-txn-${Date.now()}`
  );
  const [merchantCustomerName, setMerchantCustomerName] =
    useState("Sample Customer");
  const [displayMode, setDisplayMode] = useState<AppDisplayMode>("REDIRECT");
  const [passportSessionId, setPassportSessionId] = useState("");

  const triggerOnClick = () => {
    setIsFormVisible(true);
  };

  const onSubmit = async () => {
    const request = {
      merchant_code: merchantCode,
      pay_item_id: payableCode,
      amount: amount,
      currency: currencyCode,
      txn_ref: transactionReference || `demo-txn-${new Date().getTime()}`,
      site_redirect_url:
        "https://webpay-ui.k8.isw.la/demo-response?rlink=8&amp;txn_ref=21234",
      passportsessionid: passportSessionId,
    };

    let response: any;

    try {
      response = await axios.post(
        `${SERVICE_BASE_URL}/api/v1/pay-page`,
        request
      );
    } catch (err) {
      console.log(err);
      return;
    }

    const paymentParams = response.data.data;

    if (paymentParams && paymentParams.responseCode === "Z1") {
      alert("Failed to get new payment params. Check network response.");
      return;
    }

    initializePaymentProperties({ ...paymentParams, displayMode });

    setIsFormVisible(false);
  };

  return (
    <Container>
      <TriggerContainer onClick={triggerOnClick}>
        <SettingsIcon />
      </TriggerContainer>

      {isFormVisible && (
        <FormContainer>
          <FormCloseControl onClick={() => setIsFormVisible(false)}>
            <CloseIcon />
          </FormCloseControl>

          <ControlContainer>
            <label>Merchant Codes</label>
            <input
              value={merchantCode}
              onChange={(evt) => setMerchantCode(evt.target.value)}
            />
          </ControlContainer>

          <ControlContainer>
            <label>Payable Code</label>
            <input
              value={payableCode}
              onChange={(evt) => setPayableCode(evt.target.value)}
            />
          </ControlContainer>

          <ControlContainer>
            <label>Amount (in minor currency)</label>
            <input
              value={amount}
              onChange={(evt) => setAmount(evt.target.value)}
            />
          </ControlContainer>

          <ControlContainer>
            <label>Currency Code</label>
            <input
              value={currencyCode}
              onChange={(evt) => setCurrencyCode(evt.target.value)}
            />
          </ControlContainer>

          <ControlContainer>
            <label>Transaction Reference</label>
            <input
              value={transactionReference}
              onChange={(evt) => setTransactionReference(evt.target.value)}
            />
          </ControlContainer>

          <ControlContainer>
            <label>Merchant Customer Name</label>
            <input
              value={merchantCustomerName}
              onChange={(evt) => setMerchantCustomerName(evt.target.value)}
            />
          </ControlContainer>

          <ControlContainer>
            <label>passportSessionId</label>
            <input
              value={passportSessionId}
              onChange={(evt) => setPassportSessionId(evt.target.value)}
            />
          </ControlContainer>

          <ControlContainer>
            <label>Display type</label>
            <select
              value={displayMode}
              onChange={(evt) =>
                setDisplayMode(evt.target.value as AppDisplayMode)
              }
            >
              {displayOptions.map((option) => {
                return (
                  <option key={option} value={option}>
                    {option}
                  </option>
                );
              })}
            </select>
          </ControlContainer>

          <button onClick={onSubmit}>
            {false ? "Processing..." : "Change Payment Params"}
          </button>
        </FormContainer>
      )}
    </Container>
  );
}

const mapDispatchToProps = (
  dispatch: (action: any) => void
): StoreDispatchProps => ({
  initializePaymentProperties(paymentProps: any) {
    dispatch(triggerInitialize(paymentProps));
  },
});

export default connect(null, mapDispatchToProps)(DemoWidget);
