type FieldKey = "mobileNumber" | "email" | "amount";

export type IField = {
  label: string;
  name: FieldKey;
  type: string;
  required?: boolean;
  placeholder?: string;
  readonly: boolean;
  rules?: Record<string, any>;
};

export type IFormState = {
  mobileNumber: string;
  email: string;
  amount: string;
};

export const fields: IField[] = [
  {
    label: "Phone Number",
    name: "mobileNumber",
    type: "text",
    required: true,
    placeholder: "080 XXXX XXXX",
    readonly: false,
    rules: {
      required: "Phone number is required",
      pattern: {
        value: /^\d{11}$/,
        message: "Invalid phone number",
      },
    },
  },
  {
    label: "Email",
    name: "email",
    type: "email",
    placeholder: "Email",
    readonly: false,
    rules: {
      required: "Email is required",
      pattern: {
        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        message: "Invalid email address",
      },
    },
  },
  {
    label: "Amount",
    name: "amount",
    type: "text",
    placeholder: "Amount",
    readonly: false,
    rules: (value: string, amountDue: string) => ({
      required: "Amount is required",
      pattern: {
        value: /^[^0-].*$/,
        message: "Invalid amount",
      },
      validate: {
        isAmountGreaterThanAmountDue: () => {
          return Number(value) > Number(amountDue)
            ? "Amount must not be greater than amount due"
            : true;
        },
      },
    }),
  },
];
