import { GetTransactionStatusData, TransactionStatus } from "./types";
const transactionSuccessCodes = ['00'];
const transactionInProgressCodes = ['09', 'S0'];
const transactionExpiredCodes = ['XS1'];
const transactionCancelledCodes = ['Z6'];
const transactionErrorCodes: string[] = [];



export class PaymentUtil {
  public static isTransactionSuccessful(responseCode: string) {
    return transactionSuccessCodes.indexOf(responseCode) !== -1;
  }

  public static isTransactionInProgress(responseCode: string) {
    return transactionInProgressCodes.indexOf(responseCode) !== -1;
  }

  public static isTransactionExpired(responseCode: string) {
    return transactionExpiredCodes.indexOf(responseCode) !== -1;
  }

  public static isTransactionCancelled(responseCode: string) {
    return transactionCancelledCodes.indexOf(responseCode) !== -1;
  }

  public static isTransactionComplete(responseCode: string): boolean {
    if (
      PaymentUtil.isTransactionSuccessful(responseCode)
      || PaymentUtil.isTransactionInProgress(responseCode)
      || PaymentUtil.isTransactionExpired(responseCode)
    ) {
      return true;
    }

    return false;
  }
}

export const getTransactionStatus = (
  responseCode: string
): GetTransactionStatusData => {
  let status: TransactionStatus = "FAILURE";

  if (PaymentUtil.isTransactionSuccessful(responseCode)) {
    status = "SUCCESS"
  }

  if (PaymentUtil.isTransactionInProgress(responseCode)) {
    status = "IN_PROGRESS"
  }

  if (PaymentUtil.isTransactionExpired(responseCode)) {
    status = "EXPIRED"
  }

  if (PaymentUtil.isTransactionCancelled(responseCode)) {
    status = "CANCELLED"
  }

  
  const isSuccessful = status === "SUCCESS";
  const isPending = status === "IN_PROGRESS";
  const isFailed = !isSuccessful && !isPending;

  return { status, isSuccessful, isPending, isFailed };
};
