import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { InformationAccordion } from "../../components/InformationAccordion/InformationAccordion";
import { Layout } from "../../components/Layout";
import { RevPayForm } from "./form";
import { useValidateReference } from "../../hooks/useValidateReference";
import { PaymentCompletedView } from "../PaymentCompleted";
import { LoaderContainer } from "../../components/LoaderContainer";

export const RevPayFormView = () => {
  const { referenceDetails, validateReference, loading, error } =
    useValidateReference();

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const ref = params.get("ref") ?? "";

  useEffect(() => {
    const getDetails = async () => {
      validateReference(ref);
    };
    getDetails();
  }, [ref, validateReference]);

  if (loading || !referenceDetails) {
    return <LoaderContainer error={error} />;
  }

  if (referenceDetails?.responseCode === "01") return <PaymentCompletedView />;

  const { agencyName, revenueName, payerName, paymentReference, amount } =
    referenceDetails;

  return (
    <Layout>
      <Container>
        <FormTitle>Transaction details</FormTitle>
        <FormSubTitle>
          Complete the form below to make your process your tax payments. All
          fields are compulsory.
        </FormSubTitle>
        <FormContainer>
          <RevPayForm
            agencyName={agencyName}
            revenueName={revenueName}
            payerName={payerName}
            paymentReference={paymentReference}
            amount={amount}
          />
          <InformationAccordion />
        </FormContainer>
      </Container>
    </Layout>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  margin: 0 auto;
  width: 85%;
  @media (min-width: 1440px) {
    width: 70%;
  }
  @media (min-width: 2560px) {
    width: 55%;
  }
`;

const FormContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 60px;
  margin: 24px 0 48px;
  justify-content: space-between;
`;

const FormTitle = styled.h1`
  font-size: 2.4rem;
  font-weight: 700;
  margin-bottom: 8px;
`;

const FormSubTitle = styled.p`
    font-size: 16px;
    color: #6b6b6b;
  }
`;
