import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import LoadingIndicator from './LoadingIndicator';
import { FormattedMessage } from 'react-intl';

const StatusMessageContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: 1px solid #e2e2e2;
  padding-top: 20px;

  p {
    font-size: 1.3rem;
    color: #464646;
    margin-left: 5px;
  }
`;

export default function ConfirmStatusMessage() {
  return (
    <StatusMessageContainer>
      <div style={{ transform: 'scale(0.6)' }}>
        <LoadingIndicator color="#464646" />
      </div>
      <p>
      <FormattedMessage id="confirmTransStatus" defaultMessage="Confirming transaction status" />
      </p>
    </StatusMessageContainer>
  );
}