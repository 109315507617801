import { useCallback, useState } from "react";
import RevpayRepository, {
  NotifyPayload,
  NotifyResponse,
} from "../repository/RevpayRepository";

export const useNotifyRevpay = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<NotifyResponse | null>(null);
  const [error, setError] = useState(false);

  const handleNotification = useCallback(async (data: NotifyPayload) => {
    if (!data) {
      return;
    }

    setLoading(true);

    try {
      const res = await RevpayRepository.notify(data);
      if (res.status === 200) {
        setData(res.data);
      } else {
        setError(true);
      }
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    loading,
    handleNotification,
    error,
    data,
  };
};
