import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router-dom";
import {
  FormFieldStyles,
  FormControlLabel,
  FieldErrorText,
} from "../../../../components/Layout";
import { Button } from "../../../../components/Button";
import { fields, IField, IFormState } from "./config";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { triggerInitialize } from "../../../../payment/presentation/store/paymentProperties/actions";
import { SERVICE_BASE_URL } from "../../../../../config/properties";
import { PAYMENT_METHODS__ROOT } from "../../../../Routes";
import { nigerianCurrencyCode } from "../../../../../util/constants";
import { ReceiptInfo } from "../success";
import {
  hideNotification,
  triggerShowNotification,
} from "../../../../components/NotificationWidget/store/actions";
import NotificationWidget from "../../../../components/NotificationWidget";
import { AppState } from "../../../../../store/RootReducer";
import usePaymentChannelHelper from "../../../../../hooks/usePaymentChannelHelper";
import { PaymentParams } from "../../../../payment/presentation/store/paymentProperties/types";

interface Props {
  agencyName: string;
  revenueName: string;
  payerName: string;
  paymentReference: string;
  amount: string;
}

export const RevPayForm = ({
  agencyName,
  revenueName,
  payerName,
  paymentReference,
  amount,
}: Props) => {
  const { push } = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<IFormState>();

  const { setMerchantPaymentProps } = usePaymentChannelHelper();

  const { showNotification, notificationMessage } = useSelector(
    (state: AppState) => state.notificationWidget
  );
  const dispatch = useDispatch();

  const [processing, setProcessing] = useState(false);

  const ref = params.get("ref") ?? "";
  const currencyCode = nigerianCurrencyCode;
  const transactionReference = `txn-${Date.now()}`;

  const showErrorToast = (errorDescription?: string) => {
    dispatch(
      triggerShowNotification({
        type: "ERROR",
        message:
          errorDescription ??
          "Failed to get new payment params. Check network response.",
      })
    );
  };

  // watch amount field to check if value is greater than amount due
  const amountToPay = watch("amount");
  const isAmountGreaterThanAmountDue = Number(amountToPay) > Number(amount);

  const onSubmit: SubmitHandler<IFormState> = async (data) => {
    setProcessing(true);
    let totalCheckoutAmount = 0;

    // convert the amount to kobo
    const formattedAmount = Number(data.amount) * 100;

    const request = {
      merchant_code: REVPAY_ENV_MERCHANT_CODE,
      pay_item_id: REVPAY_ENV_PAYITEM_ID,
      amount: formattedAmount,
      currency: currencyCode,
      txn_ref: transactionReference,
      cust_id: data.email,
      site_redirect_url: `${window.location.origin}/revpay-success`,
    };

    let paymentParams = {} as PaymentParams;

    try {
      const response = await axios.post(
        `${SERVICE_BASE_URL}/api/v1/pay-page`,
        request
      );

      if (response.status === 200) {
        paymentParams = response.data.data;

        // Set new payment params globally
        setMerchantPaymentProps(paymentParams);

        const { responseCode, checkoutAmount } = paymentParams;

        totalCheckoutAmount = checkoutAmount;

        if (responseCode && responseCode !== "00") {
          showErrorToast(paymentParams.responseDescription);
          return;
        }
      }
    } catch (err) {
      console.log(err);

      showErrorToast();
      return;
    } finally {
      setProcessing(false);
    }

    const receipt_info: ReceiptInfo = {
      amount: totalCheckoutAmount,
      payer_name: payerName ?? "",
      ref: ref,
      reference: paymentReference ?? "",
      paymentId: paymentParams.paymentId,
    };

    sessionStorage.setItem("receipt_info", JSON.stringify(receipt_info));

    // Revpay post transaction should INLINE
    dispatch(
      triggerInitialize({
        ...paymentParams,
        displayMode: "INLINE",
      })
    );

    push(PAYMENT_METHODS__ROOT);
  };

  const getFieldRules = (field: IField) => {
    if (field.name === "amount") {
      return field.rules && typeof field.rules === "function"
        ? { ...field.rules(amountToPay, amount) }
        : {};
    }
  };

  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
      {showNotification && (
        <NotificationWidget
          type={notificationMessage?.type ?? "INFO"}
          message={notificationMessage?.message ?? ""}
          closeHandler={hideNotification}
        />
      )}
      <FieldWrapper>
        <FormControlLabel>Billing reference</FormControlLabel>
        <InputField value={paymentReference} readOnly />
      </FieldWrapper>
      <FieldWrapper>
        <FormControlLabel>Agency name</FormControlLabel>
        <InputField value={agencyName} readOnly />
      </FieldWrapper>
      <FieldWrapper>
        <FormControlLabel>Revenue name</FormControlLabel>
        <InputField value={revenueName} readOnly />
      </FieldWrapper>
      <FieldWrapper>
        <FormControlLabel>Payer's name</FormControlLabel>
        <InputField value={payerName} readOnly />
      </FieldWrapper>
      <FieldWrapper>
        <FormControlLabel>Amount due</FormControlLabel>
        <InputField value={amount} readOnly />
      </FieldWrapper>

      {fields.map((field) => (
        <FieldWrapper key={field.name}>
          <FormControlLabel>{field.label}</FormControlLabel>
          <InputField
            type="text"
            placeholder={field.placeholder}
            {...register(
              field.name,
              field.name === "amount" ? getFieldRules(field) : field.rules
            )}
            error={!!errors[field.name]}
          />
          {errors[field.name] && (
            <FieldErrorText>
              {errors[field.name]?.type !== "isAmountGreaterThanAmountDue" &&
                errors[field.name]?.message}
            </FieldErrorText>
          )}

          {/*React hook form does not update it's error object on every render for the 'amount; field. 
          So, there is need to show an error message based on the local variable*/}
          {field.name === "amount" && (
            <FieldErrorText>
              {isAmountGreaterThanAmountDue &&
                "Amount must not be greater than amount due"}
            </FieldErrorText>
          )}
        </FieldWrapper>
      ))}

      <Button
        containerStyle={{ width: "100%", marginTop: "32px" }}
        color="PRIMARY"
        text="Make Payment"
        loading={processing}
        buttonType="submit"
      />
      <Disclaimer>
        By clicking on “Make Payment” you confirm that you read and consent to
        the Terms of Use and Privacy Policy of WebPay.
      </Disclaimer>
    </Container>
  );
};

const Container = styled.form`
  width: 55%;
  max-width: 800px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const InputField = styled.input<{ error?: boolean }>`
  ${FormFieldStyles};
  margin-top: 2px;
  font-size: 1.3rem;

  ${(props) => props.error && `border-color: #ff0000;`}

  &:read-only {
    background-color: #f5f5f5;
    color: #121212ad;
  }
`;
const FieldWrapper = styled.div`
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
`;
const Disclaimer = styled.p`
  color: #6b6b6b;
  font-size: 11px;
  margin-top: 4px;
`;
