import {
  TRIGGER_INITIALIZE,
  TriggerInitialize,
  INITIALIZE_COMPLETE,
  InitializeComplete,
  InitializeCompletePayload,
} from "./types";

export const triggerInitialize = (paymentProps: any): TriggerInitialize => {
  return {
    type: TRIGGER_INITIALIZE,
    payload: { paymentProperties: paymentProps },
  };
};

export const initializeComplete = (
  payload: InitializeCompletePayload
): InitializeComplete => {
  return {
    type: INITIALIZE_COMPLETE,
    payload: payload,
  };
};
