import httpClient from "../../../../core/api/httpClient";
import { SERVICE_BASE_URL } from "../../../../config/properties";
import { AxiosResponse } from "axios";

export interface ValidateReferenceResponse {
  webguid: string;
  amount: string;
  payerName: string;
  pid: string;
  revenueName: string;
  agencyName: string;
  responseCode: string;
  paymentFlag: string;
  paymentReference: string;
}

export interface NotifyPayload {
  webguid: string;
  amount: string;
  paymentId: number;
  paymentReference: string;
}

export interface NotifyResponse {
  webguid: string;
  amount: string;
  payerName: string;
  responseCode: string;
  paymentReference: string;
  receiptLink: string;
}

const validate = async (
  guid: string
): Promise<AxiosResponse<ValidateReferenceResponse>> => {
  return httpClient.post(
    `${SERVICE_BASE_URL}/api/v1/revpay/validate-reference?webguid=${guid}`,
    {}
  );
};

const notify = async (
  data: NotifyPayload
): Promise<AxiosResponse<NotifyResponse>> => {
  return httpClient.post(
    `${SERVICE_BASE_URL}/api/v1/revpay/notify-revpay`,
    data
  );
};

const RevpayRepository = {
  validate,
  notify,
};

export default RevpayRepository;
