import React, { CSSProperties, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { FormControlsTheme } from '../../../styling/theme';
import { FieldLabel, FormFieldErrorStyles, FormLabelFieldStyles } from '../Layout';

import { ReactComponent as CLoseIcon } from '../../../assets/icons/close-icon.svg';
import { ReactComponent as CvvIcon } from './cvv-icon.svg';
import { eventIsNumberKey, isKeyboardAction } from '../../../core/util/eventUtils';
import { isDigitNumber } from '../../../core/util/numUtil';
import { FormattedMessage } from 'react-intl';

const Container = styled.div`
  position: relative;
`;


const HintText = styled(FieldLabel)`
  left: unset;
  right: ${FormControlsTheme.padding.side}px;
  font-size: 1.1rem;
  color: #b6b6b6;
  cursor: pointer;
`

const ShowAnimation = keyframes`
  0% {
    opacity: 0;
  };
  100% {
    opacity: 1;
  };
`;

const HintDescriptionContainer = styled.div`
  position: absolute;
  bottom: calc(100% + 5px);
  right: 0px;
  width: 200px;
  padding: 10px 10px;
  box-sizing: border-box;
  background: white;
  box-shadow: 0 6px 15px rgba(0,0,0,.22);
  border: 1px solid rgba(0,0,0,.1);
  border-radius: 3px;
  animation: ${ShowAnimation} 0.2s ease-in;

  p {
    font-size: 1.2rem;
    line-height: 20px;
  }
`;


const HintCloseButton = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    width: 10px;
    height: 10px;
  }
`;

const CvvIconContainer = styled.div`
  margin-bottom: 5px;

  svg {
    height: 30px;
    width: auto;
    fill: #737373;
  }
`;

interface InputFieldProps {
  error: boolean;
}

const InputField = styled.input<InputFieldProps>`
  ${FormLabelFieldStyles};
  ${({ error }) => error && FormFieldErrorStyles};
`;

interface OwnProps {
  value: string;
  error?: boolean;
  containerStyle?: CSSProperties;
  onValueChange: (value: string) => void;
}

type Props = OwnProps & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export default function CvvInputField(props: Props) {
  const { value, error = false, containerStyle, onValueChange, ...inputProps } = props;

  const { ref, ...extractedInputProps } = inputProps;

  const [isHintVisibe, setIsHintVisibe] = useState(false);

  const displayHint = (evt: React.MouseEvent<HTMLLabelElement, MouseEvent>) => {
    evt.nativeEvent.stopImmediatePropagation();
    setIsHintVisibe(true);
  };

  const hideHint = () => {
    setIsHintVisibe(false);
  }

  const onKeyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (isKeyboardAction(event)) {
      return;
    }

    let keyValid: boolean = eventIsNumberKey(event);

    if (!keyValid) {
      event.preventDefault();
      return;
    }
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    const { value } = target;

    const numString = value.split('').reduce((ac, currVal) => {
      const appendVal = isDigitNumber(currVal) ? currVal : '';
      return ac + appendVal;
    }, '');

    onValueChange(numString);
  };

  useEffect(() => {
    document.addEventListener('click', hideHint);
    return () => document.addEventListener('click', hideHint);
  }, []);

  return (
    <Container>
      <HintText onClick={displayHint}>
        <FormattedMessage id="whatIsThis" defaultMessage="What's this?" />
      </HintText>

      {isHintVisibe && (
        <HintDescriptionContainer>
          <HintCloseButton onClick={hideHint}>
            <CLoseIcon />
          </HintCloseButton>

          <CvvIconContainer>
            <CvvIcon />
          </CvvIconContainer>

          <p><FormattedMessage id="the" defaultMessage="The" /> <b><FormattedMessage id="cvv" defaultMessage="CVV" /></b> <FormattedMessage id="whatIsThisDesc" defaultMessage="is a 3 or 4 digit code located at the back of your card." /></p>
        </HintDescriptionContainer>
      )}

      <InputField
        value={value}
        inputMode="numeric"
        placeholder="123"
        type="tel"
        autoComplete="cc-csc"
        maxLength={4}
        error={error}
        onKeyDown={onKeyDownHandler}
        onChange={onChange}
        {...extractedInputProps}
      />
    </Container>
  );
}
