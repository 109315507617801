import * as React from 'react';
import styled from 'styled-components';

import { PageView } from '../../../components/Layout';
import useRequery from './hooks/useRequery';
import UssdView from './components/UssdNotification';
import BackControl from '../../../components/BackControl';
import { useHistory } from 'react-router-dom';
import { PAYMENT_METHODS__ROOT } from '../../../Routes';
import ErrorView from '../../../components/ErrorView';
import translate from '../../../../translations/translate';

export default function DrcRequeryView() {
	const history = useHistory();

	const goBack = () => {
		history.push(PAYMENT_METHODS__ROOT);
	};

	const { getStatus, error } = useRequery();

	return (
		<Container>
			<BackControl text={translate('changePaymentMethod', "Change payment method")} onClick={goBack} />

			{/* {loading && (
        <div className="loader-box">
          <Loader />
        </div>
      )} */}

			{error && <ErrorView action={getStatus} />}
			<UssdView />
		</Container>
	);
}

const Container = styled(PageView)`
	.header {
		h4 {
			font-size: 1.7rem;
			margin-bottom: 2px;
			color: #152a38;
		}
		p {
			font-size: 1.2rem;
			color: #616161;
		}
	}

	.loader-box {
		height: 200px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
`;
