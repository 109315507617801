import { AxiosError } from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { getAxiosErrorData } from '../../../../../core/api/helpers';
import { asyncDelay } from '../../../../../core/util/asyncUtil';
import usePrevious from '../../../../../hooks/usePrevious';
import { AppState } from '../../../../../store/RootReducer';
import { PAGE_SIDE_PADDING } from '../../../../../styling/constants';
import BackControl from '../../../../components/BackControl';
import { PageView } from '../../../../components/Layout';
import LoadingView from '../../../../components/LoadingView';
import { triggerShowNotification } from '../../../../components/NotificationWidget/store/actions';
import { TriggerShowNotificationPayload } from '../../../../components/NotificationWidget/store/types';
import { PaymentChannelType, PaymentParams } from '../../../../payment/presentation/store/paymentProperties/types';
import { completeTransaction } from '../../../../payment/presentation/store/paymentStatus/actions';
import { PaymentUtil } from '../../../../payment/util/PaymentUtil';
import { WalletUserData } from '../../../../wallet/domain/repositories/WalletRepository';
import { AuthenticateCardinalRequest, AuthenticateCardinalResponse, CardinalAuthenticationInformation, CardinalDeviceInfo, CardinalInfo, CardRepository, MakePaymentRequest, MakePaymentResponse } from '../../../domain/repositories/CardRepository';
import getCardinalDeviceInformation from "../../../utils/deviceInformation";
import { generateSecureData } from '../../../utils/secureGenerator';
import { resetAuthenticateCardinal, triggerAuthenticateCardinal } from '../../store/authenticateCardinal/actions';
import { CachedCardParams } from '../../store/cachedCardDetails/types';
import { resetMakePayment, triggerMakePayment } from '../../store/makePayment/actions';
import RootIFrame from './RootIFrame';
import translate from '../../../../../translations/translate';

async function performCardinalDeviceCollection(
  collectionUrl: string,
  jwt: string,
  bin: string
) {
  const iframeId = "device-collection-iframe";
  const formId = "device-collection-form";

  const iframeEl = document.createElement("iframe");
  iframeEl.id = iframeId;
  iframeEl.name = "deviceCollectionIframe";
  iframeEl.width = "1";
  iframeEl.style.display = "none";

  document.body.appendChild(iframeEl);

  const formEl = document.createElement("form");
  formEl.id = formId;
  formEl.method = "POST";
  formEl.target = "deviceCollectionIframe";
  formEl.action = collectionUrl;

  const inputJwtEl = document.createElement("input");
  inputJwtEl.id = "cardinal_collection_form_input";
  inputJwtEl.type = "hidden";
  inputJwtEl.name = "JWT";
  inputJwtEl.value = jwt;

  const inputBinEl = document.createElement("input");
  inputBinEl.id = "";
  inputBinEl.type = "hidden";
  inputBinEl.name = "Bin";
  inputBinEl.value = bin;

  formEl.appendChild(inputJwtEl);
  formEl.appendChild(inputBinEl);

  document.body.appendChild(formEl);

  const insertedFormEl = document.getElementById(formId) as (HTMLFormElement | null);

  if (insertedFormEl) insertedFormEl.submit();

  await asyncDelay(7000);

  // remove device collection iframe and form
  var pageIframeEl = document.getElementById(iframeId);

  if (pageIframeEl) {
    document.body.removeChild(pageIframeEl);
  }

  var pageFormEl = document.getElementById(formId);

  if (pageFormEl) {
    document.body.removeChild(pageFormEl);
  }
}

const Container = styled(PageView)`
  padding-left: 0px;
  padding-right: 0px;
`;

const BackControlContainer = styled.div`
  padding-left: ${PAGE_SIDE_PADDING}px;
  padding-right: ${PAGE_SIDE_PADDING}px;
`;

const IFrameContainer = styled.div`
  position: relative;
  min-height: 370px;
  display: flex;
`;

const LoadingViewContainer = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: white;
`;

export interface CardCardinalPageLocationState {
  cardinalAuthenticationInformation?: CardinalAuthenticationInformation;
  routedMakePaymentPayload?: MakePaymentRequest;
  routedMakePaymentResponse?: MakePaymentResponse;
}

interface StoreStateProps {
  paymentParams: PaymentParams;
  currentPaymentChannel: PaymentChannelType;
  cachedCardParams: CachedCardParams;
  userData: WalletUserData | null;

  makePaymentPending: boolean;
  makePaymentError: AxiosError | null;
  makePaymentResponse: MakePaymentResponse | null;

  authenticateCardinalPending: boolean;
  authenticateCardinalError: AxiosError | null;
  authenticateCardinalResponse: AuthenticateCardinalResponse | null;
}

interface StoreDispatchProps {
  showNotification: (payload: TriggerShowNotificationPayload) => void;
  completeTransaction: (transactionResponse: any) => void;
  makePayment: (request: MakePaymentRequest) => void;
  authenticateCardinal: (request: AuthenticateCardinalRequest) => void;
  resetMakePayment: () => void;
  resetAuthenticateCardinal: () => void;
}

interface OwnProps {
  cardRepository: CardRepository;
}

type Props = StoreStateProps & StoreDispatchProps & OwnProps;

export function CardCardinalPage(props: Props) {
  const {
    cardRepository,
    paymentParams,
    currentPaymentChannel,
    cachedCardParams,
    userData,

    makePaymentPending,
    makePaymentError,
    makePaymentResponse,

    authenticateCardinalPending,
    authenticateCardinalError,
    authenticateCardinalResponse,

    showNotification,
    completeTransaction,
    makePayment,
    authenticateCardinal,

    resetMakePayment,
    resetAuthenticateCardinal,
  } = props;

  const prevMakePaymentPending = usePrevious(makePaymentPending);
  const prevAuthenticateCardinalPending = usePrevious(authenticateCardinalPending);

  const history = useHistory();

  const { location: { state: locationState } } = history;

  const isPageMounted = useRef(true);

  const makePaymentResponseRef = useRef<MakePaymentResponse>();

  const [isLoadingVisible, setIsLoadingVisible] = useState(true);

  const navigateToPaymentMethods = () => {
    history.goBack();
  }

  const initiatePayment = () => {
    const { secure, pinBlock } = generateSecureData(
      cachedCardParams.cardNumber,
      cachedCardParams.cardExpiry,
      cachedCardParams.cardCvv
    );

    const { 
      cardinalAuthenticationInformation 
    } = locationState as CardCardinalPageLocationState;

    let sessionId:string | undefined;
    const deviceInformation: CardinalDeviceInfo | undefined = getCardinalDeviceInformation();

    if (cardinalAuthenticationInformation) {
      sessionId = cardinalAuthenticationInformation.referenceId;
    }

    if (currentPaymentChannel === "WALLET") {
      const { paymentMethod } = cachedCardParams;
      if (!paymentMethod || !userData) return;

      const { mobileNo } = userData;
      const { name, cardIdentifier, walletInstrumentIdentifier } = paymentMethod;

      makePayment({
        secureData: secure,
        pinBlock: pinBlock,
        merchantCode: paymentParams.merchantCode,
        payableCode: paymentParams.payableCode,
        paymentId: paymentParams.paymentId,
        instrumentIdentifier: cardIdentifier,
        walletInfo: {
          walletId: mobileNo,
          walletCardName: name,
          walletIdentifier: walletInstrumentIdentifier,
        },
        sessionId: sessionId,
        deviceInformation,
      });
      return;
    }

    makePayment({
      merchantCode: paymentParams.merchantCode,
      payableCode: paymentParams.payableCode,
      paymentId: paymentParams.paymentId,
      secureData: secure,
      pinBlock: pinBlock,
      sessionId: sessionId,
      googlePayToken: cachedCardParams.googlePayToken,
      deviceInformation,
    });
  };

  const init = async () => {
    const { cardinalAuthenticationInformation } = locationState as CardCardinalPageLocationState;

    if (!cardinalAuthenticationInformation) {
      initiatePayment();
      return;
    }

    const {
      deviceDataCollectionUrl, accessToken,
    } = cardinalAuthenticationInformation;

    let pan = '';

    if (currentPaymentChannel === "WALLET") {
      const { paymentMethod } = cachedCardParams;
      if (!paymentMethod) return;

      const { maskedPan } = paymentMethod;
      pan = maskedPan;
    } else {
      const { cardNumber } = cachedCardParams;
      pan = cardNumber;
    }

    const bin = pan.slice(0, 6);

    await performCardinalDeviceCollection(
      deviceDataCollectionUrl, accessToken, bin,
    );

    if (!cardinalAuthenticationInformation && isPageMounted.current) {
      initiatePayment();
    }
  };

  const callAuthenticateCardinal = () => {
    const { routedMakePaymentResponse, routedMakePaymentPayload } = locationState as CardCardinalPageLocationState;

    const makePaymentResponse = makePaymentResponseRef.current || routedMakePaymentResponse;

    if (!makePaymentResponse || !makePaymentResponse.cardinalInfo) return;

    const { cardinalInfo } = makePaymentResponse;

    const secureData = generateSecureData(
      cachedCardParams.cardNumber,
      cachedCardParams.cardExpiry,
      cachedCardParams.cardCvv,
    );

    const secure = routedMakePaymentPayload?.secureData ?? secureData.secure
    const pinBlock = routedMakePaymentPayload?.pinBlock ?? secureData.pinBlock

    if (currentPaymentChannel === "WALLET") {
      const { paymentMethod } = cachedCardParams;
      if (!paymentMethod || !userData) return;

      const { mobileNo } = userData;
      const { name, cardIdentifier, walletInstrumentIdentifier } = paymentMethod;

      authenticateCardinal({
        paymentId: paymentParams.paymentId,
        cardinalInfo: {
          eciFlag: cardinalInfo.eciFlag as string,
          transactionId: cardinalInfo.transactionId as string,
        },
        secureData: secure,
        pinBlock: pinBlock,
        instrumentIdentifier: cardIdentifier,
        walletInfo: {
          walletId: mobileNo,
          walletCardName: name,
          walletIdentifier: walletInstrumentIdentifier,
        }
      });
      return;
    }
    authenticateCardinal({
      paymentId: paymentParams.paymentId,
      cardinalInfo: {
        eciFlag: cardinalInfo.eciFlag as string,
        transactionId: cardinalInfo.transactionId as string,
      },
      secureData: secure,
      pinBlock: pinBlock,
      googlePayToken: cachedCardParams.googlePayToken
    });
  };

  const pollTransactionStatus = async () => {
    while (true) {
      if (!isPageMounted.current) return;

      let response;

      try {
        response = await cardRepository.getCardinalTransactionStatus({
          paymentId: paymentParams.paymentId
        });
      } catch (err) {
        await asyncDelay(2000);
        continue;
      }

      const { responseCode } = response;

      if (responseCode === "T1") {
        await asyncDelay(2000);
        continue;
      }

      if (responseCode === "T0") {
        callAuthenticateCardinal();
        setIsLoadingVisible(true);
        break;
      }
    }
  }

  const onFrameLoad = () => {
    setIsLoadingVisible(false);
  };

  useEffect(() => {
    init();
    pollTransactionStatus();

    return () => {
      isPageMounted.current = false;
      resetMakePayment();
      resetAuthenticateCardinal();
    }
  }, []);

  useEffect(() => {
    if (!(prevMakePaymentPending && !makePaymentPending)) return;

    if (makePaymentError) {
      const responseData = getAxiosErrorData(makePaymentError);

      if (!responseData) {
        showNotification({ type: 'ERROR' });
        history.goBack();
        return;
      }

      const { responseCode } = responseData;

      if (PaymentUtil.isTransactionComplete(responseCode)) {
        completeTransaction(responseData);
        return;
      }

      showNotification({ type: 'ERROR' });
      history.goBack();
      return;
    }

    if (!makePaymentResponse) return;

    makePaymentResponseRef.current = makePaymentResponse;

    const {
      responseCode, requiresCentinelAuthorization
    } = makePaymentResponse;

    if (PaymentUtil.isTransactionComplete(responseCode)) {
      completeTransaction(makePaymentResponse);
      return;
    }

    if (responseCode === 'T0' && requiresCentinelAuthorization) return;

    showNotification({ type: 'ERROR' });
    history.goBack();
  }, [makePaymentPending]);

  useEffect(() => {
    if (!(prevAuthenticateCardinalPending && !authenticateCardinalPending)) return;

    if (authenticateCardinalError) {
      const responseData = getAxiosErrorData(authenticateCardinalError);

      const isFraud = Boolean(
        responseData &&
          responseData.responseCode &&
          responseData.responseCode === "S3"
      );
      const fraudMEssage =
        "Transaction declined. Please select or use another card";

      if (!responseData) {
        showNotification({ type: 'ERROR' });
        
        // Abort payment
        completeTransaction({
          responseCode: responseData.responseCode,
          merchantTransactionReference:
            paymentParams.merchantTransactionReference,
        });
        return;
      }

      const { responseCode } = responseData;

      if (PaymentUtil.isTransactionComplete(responseCode)) {
        completeTransaction(responseData);
        return;
      }

      showNotification({
        type: 'ERROR',
        message: isFraud ? fraudMEssage : undefined,
      });
      history.goBack();
      return;
    }

    if (!authenticateCardinalResponse) return;

    const { responseCode } = authenticateCardinalResponse;

    if (PaymentUtil.isTransactionComplete(responseCode)) {
      completeTransaction(authenticateCardinalResponse);
      return;
    }

    // Abort payment
    completeTransaction({
      responseCode,
      merchantTransactionReference:
        paymentParams.merchantTransactionReference,
    });

    history.goBack();
  }, [authenticateCardinalPending]);


  let cardinalInfo: CardinalInfo | null = null;

  if (makePaymentResponse && makePaymentResponse.cardinalInfo) {
    cardinalInfo = makePaymentResponse.cardinalInfo;
  }


  return (
    <Container data-testid="card-cardinal-page">
      <BackControlContainer>
        <BackControl
          text={translate('back', 'Back')}
          onClick={navigateToPaymentMethods}
        />
      </BackControlContainer>


      <IFrameContainer>
        {isLoadingVisible && (
          <LoadingViewContainer>
            <LoadingView />
          </LoadingViewContainer>
        )}

        {cardinalInfo && (
          <RootIFrame
            paymentId={paymentParams.paymentId}
            url={cardinalInfo.url}
            callBackUrl={cardinalInfo.callBackUrl}
            payload={cardinalInfo.payLoad}
            jwt={cardinalInfo.accessToken}
            onFrameLoad={onFrameLoad}
            customisedHtml={cardinalInfo.customisedHtml}
          />
        )}
      </IFrameContainer>

    </Container>
  );
}


const mapStateToProps = (state: AppState): StoreStateProps => ({
  paymentParams: state.payment.paymentProperties.paymentParams as PaymentParams,
  currentPaymentChannel: state.payment.currentPaymentChannel as PaymentChannelType,
  cachedCardParams: state.card.cachedCardDetails.cachedCardParams as CachedCardParams,
  userData: state.wallet.userWalletData.userData,

  makePaymentPending: state.card.makePayment.makePaymentPending,
  makePaymentError: state.card.makePayment.makePaymentError,
  makePaymentResponse: state.card.makePayment.makePaymentResponse,

  authenticateCardinalPending: state.card.authenticateCardinal.authenticateCardinalPending,
  authenticateCardinalError: state.card.authenticateCardinal.authenticateCardinalError,
  authenticateCardinalResponse: state.card.authenticateCardinal.authenticateCardinalResponse,
});

const mapDispatchToProps = (dispatch: (action: any) => void): StoreDispatchProps => ({
  showNotification(payload: TriggerShowNotificationPayload) {
    dispatch(triggerShowNotification(payload))
  },
  completeTransaction(transactionResponse: any) {
    dispatch(completeTransaction(transactionResponse));
  },
  makePayment(request: MakePaymentRequest) {
    dispatch(triggerMakePayment(request));
  },
  authenticateCardinal(request: AuthenticateCardinalRequest) {
    dispatch(triggerAuthenticateCardinal(request));
  },
  resetMakePayment() {
    dispatch(resetMakePayment());
  },
  resetAuthenticateCardinal() {
    dispatch(resetAuthenticateCardinal());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CardCardinalPage);