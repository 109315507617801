const testPaymentProps = {
  amount: 1000,
  baseUrl: "/collections/",
  checkoutAmount: 0,
  consentUrl: "https://consent.interswitchng.com/",
  currencyCode: "566",
  customerId: "0000000001",
  customerName: "Demo Customer",
  cybersourceMerchantId: "merchantinter",
  cybersourceOrgId: "5fb66c5054741f6593376917",
  // tucRewardPercentage: "80.0",
  // tucRewardDescription: "To get up to 80.0% cashback from sadd, enter your phone number or ThankUCash card ID below and proceed to payment",
  // cancellationPeriod: "2 hours",
  // refundDuration: "3 days",
  // escrowFee: "2000",
  displayMode: "REDIRECT",
  logoUrl: "https://mufasa-qa.interswitchng.com/p/webpay/logos/default.png",
  //ZIB Merchant
  // merchantCode: "MX116485",
  // merchantName: "Ramoni Enterprises",
  // merchantCode: "MX100434",
  // merchantName: "Fatima ent",
  //HALO
  // merchantCode: "MX112607",
  // merchantName: "Ossy Nice",
  //ISW Merchant
  merchantCode: "MX116416",
  merchantName: "Eke cars",
  // merchantCode: "VNA",
  // merchantName: "VNA PAYDirect",
  //MUL
  // merchantCode: 'MW198412',
  // merchantName: 'Magaasin Andre',
  merchantTransactionReference: "1618233871459",
  nonCardOptions: [
    {
      payableCode: "3790757",
      providerCode: "CARD",
      enabled: true,
      additionalInformation: null,
    },
    {
      payableCode: "3790757",
      providerCode: "QR",
      enabled: true,
      additionalInformation: null,
    },
    {
      payableCode: "3790757",
      providerCode: "TRANSFER",
      enabled: true,
      additionalInformation: null,
    },
    {
      payableCode: "3790757",
      providerCode: "USSD",
      enabled: true,
      additionalInformation: null,
    },
    {
      payableCode: "3790757",
      providerCode: "CUSTOMER_WALLET",
      enabled: true,
      additionalInformation: {
        providers: [
          {
            id: 123,
            code: "opay",
            description: "Opay",
            countryCode: "NG",
          },
          {
            id: 125,
            code: "enaira",
            description: "eNaira",
            countryCode: "NG",
          },
          {
            id: 124,
            code: "terrapay",
            description: "Terrapay Wallet Providers",
            countryCode: "NG",
          },
          {
            id: 3,
            code: "pocket",
            description: "Pocket",
            countryCode: "NG",
          },
        ],
      },
    },
    {
      payableCode: "9405967",
      providerCode: "UGANDA_MOBILE_MONEY",
      enabled: true,
      additionalInformation: {
        categoryId: "60046",
      },
    },
    // {
		// 	payableCode: '9405967',
		// 	providerCode: 'DRC_MOBILE_MONEY',
		// 	enabled: true,
		// 	additionalInformation: {
		// 		categoryId: '60047',
		// 	},
		// },
  ],
  payableCode: "108",
  payableId: 8,
  paymentId: 3512115,
  siteRedirectUrl: "http://qa.interswitchng.com/collections/responsePage",
  surcharge: 400,
  transactionDate: 1618237328327,
  transactionType: "REGULAR",
  virtualProductNetworkId: "",
  // acquiredBy: 'MUL',
  userName: "",
  merchantEmail: "SUNRAY2201@GMAIL.COM.zib",
  status: "SUBSCRIBED",
  name: "Ramoni Enterprises",
  logoData: "",
  // countryCode: 'Democratic Republic of Co',
  // country: 'CD',
  passFeeToCustomer: true,
  //Merchant details
  merchantDetails: {
    userName: "SUNRAY2201@GMAIL.COM.zib",
    merchantCode: "MX116485",
    merchantEmail: "SUNRAY2201@GMAIL.COM.zib",
    status: "SUBSCRIBED",
    name: "Ramoni Enterprises",
    logoUrl: "",
    logoData: "",
    countryCode: "",
    country: "",
    // acquiredBy: 'ZIB',
  },
};

testPaymentProps.checkoutAmount =
  testPaymentProps.amount + testPaymentProps.surcharge;

export default testPaymentProps;