export function getFormattedInterval(interval: number) {
  const intervalStr = interval.toString();

  return intervalStr.length > 1 ? intervalStr : `0${intervalStr}`;
}

export function getCurrentYear() {
  const now = new Date();
  return now.getFullYear();
}
