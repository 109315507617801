import React, { useEffect, useState } from "react";
import PaymentRootView from "./PaymentRootView";
import { RevpayRoot } from "./revpay/views/";
import { useLocation } from "react-router-dom";

export const RootView = () => {
  const location = useLocation();
  const isRevPay = location.pathname.includes("revpay");

  const [view, setView] = useState<"revpay" | "payment">(() => {
    return isRevPay ? "revpay" : "payment";
  });

  useEffect(() => {
    if (isRevPay) {
      setView("revpay");
    } else {
      setView("payment");
    }
  }, [isRevPay]);

  if (view === "revpay") return <RevpayRoot />;

  return <PaymentRootView />;
};
