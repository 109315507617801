import React from "react";
import styled from "styled-components";
import { Header } from "./Header";
import { Footer } from "./Footer";

interface LayoutProps {
  children: React.ReactNode;
}

export const Layout = ({ children }: LayoutProps) => {
  return (
    <Container>
      <Header />
      {children}
      <Footer />
    </Container>
  );
};

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;
