import React, { useCallback, useEffect } from "react";
import MethodItem from "../../../../components/MethodItem";
import { connect, useDispatch } from "react-redux";
import { AppState } from "../../../../../store/RootReducer";
import {
  WalletPaymentMethod,
  WalletUserData,
} from "../../../../wallet/domain/repositories/WalletRepository";

import { Container } from "./style";

import {
  PaymentChannelType,
  PaymentParams,
  SET_ESCROW_STATUS,
  SET_TUC_STATUS,
} from "../../store/paymentProperties/types";
import EscrowPopup from "./components/EscrowPopup";
import TUCPopup from "./components/TUCPopup";
import OPayIconPath from "./icons/opay.svg";
import useOnMethodSelect from "../../../../../hooks/useOnMethodSelect";
import usePaymentChannelHelper from "../../../../../hooks/usePaymentChannelHelper";
import { paymentChannelsProps } from "../../../../components/Constants";

interface StoreStateProps {
  paymentParams: PaymentParams;
  userData: WalletUserData | null;
  walletCards: WalletPaymentMethod[] | null;
  tucAccepted?: boolean;
  escrowAccepted?: boolean;
}

interface StoreDispatchProps {
  setPaymentChannel: (channelType: PaymentChannelType) => void;
}

interface OwnProps {}

type Props = StoreStateProps & StoreDispatchProps & OwnProps;

export function SelectPaymentMethodPage(props: Props) {
  const { paymentParams, tucAccepted, escrowAccepted } = props;

  const onMethodSelect = useOnMethodSelect();
  const { isChannelEnabled, isOnlyCardEnabled } = usePaymentChannelHelper();

  const hasTUCEnabled = React.useMemo(
    () =>
      !!(
        paymentParams.tucRewardPercentage && paymentParams.tucRewardDescription
      ),
    [paymentParams.tucRewardPercentage, paymentParams.tucRewardDescription]
  );

  useEffect(() => {
    const enabledPayments = paymentParams?.nonCardOptions?.filter(
      (method) => method.enabled
    );

    if (!enabledPayments) return;

    const isSinglePayment = enabledPayments.length === 1;
    if (isSinglePayment) {
      const singlePaymentChannel = enabledPayments[0].providerCode;

      onMethodSelect(singlePaymentChannel, isSinglePayment);
    }
  }, [onMethodSelect, paymentParams.nonCardOptions]);

  // useEffect(() => {
  //   const enabledChannels = Object.keys(enabledChannelsMap).filter(
  //     (channel) => !!enabledChannelsMap[channel as PaymentChannelType]
  //   );
  //   const acquiredBy = sessionStorage.getItem("acquiredBy");
  //   if (enabledChannels.length === 1 && !hasTUCEnabled && acquiredBy !== "ZIB") {
  //     onMethodSelect(enabledChannels[0] as PaymentChannelType);
  //   }
  // }, [enabledChannelsMap, paymentChannels, onMethodSelect, hasTUCEnabled]);

  const dispatch = useDispatch();

  const onTUCDone = useCallback(() => {
    dispatch({
      type: SET_TUC_STATUS,
      payload: true,
    });
  }, [dispatch]);

  const onEscrowDone = useCallback(() => {
    dispatch({
      type: SET_ESCROW_STATUS,
      payload: true,
    });
  }, [dispatch]);

  const hasEscrowEnabled =
    !!paymentParams &&
    !!(
      paymentParams.escrowFee &&
      paymentParams.cancellationPeriod &&
      paymentParams.refundDuration
    ) &&
    !escrowAccepted;
  console.log(paymentChannelsProps, "paymentChannelsProps");
  return (
    <Container>
      {hasTUCEnabled && !tucAccepted && (
        <TUCPopup onDone={onTUCDone} paymentParams={paymentParams} />
      )}

      {hasEscrowEnabled && (
        <EscrowPopup onDone={onEscrowDone} paymentParams={paymentParams} />
      )}

      {paymentChannelsProps.map((props) => {
        const { type, featuredTag, title, imageIcon, description, plainIcon } =
          props;

        if (!isChannelEnabled(type)) return null;

        // if merchant has only card enabled, hide quickteller option
        if (isOnlyCardEnabled && type === "WALLET") return null;

        return (
          <MethodItem
            key={type}
            type={type}
            featuredTag={featuredTag}
            title={title}
            description={description}
            imageIcon={type !== "OPAY" ? imageIcon : undefined}
            iconPath={type === "OPAY" ? OPayIconPath : undefined}
            plainIcon={plainIcon}
            onSelect={() => onMethodSelect(type)}
          />
        );

        // return (
        //   <MethodContainer
        //     data-testid={type}
        //     key={type}
        //     onClick={() => onMethodSelect(type)}
        //   >
        //     <IconContainer className={plainIcon ? "plain" : undefined}>
        //       {imageIcon}
        //     </IconContainer>

        //     <DetailsContainer>
        //       <h6>{title}</h6>
        //       <p>{description}</p>
        //     </DetailsContainer>

        //     <ArrowIconContainer>
        //       <RightArrowIcon />
        //     </ArrowIconContainer>
        //   </MethodContainer>
        // );
      })}
    </Container>
  );
}

const mapStateToProps = (state: AppState): StoreStateProps => ({
  paymentParams: state.payment.paymentProperties.paymentParams as PaymentParams,
  tucAccepted: state.payment.paymentProperties.tucAccepted,
  escrowAccepted: state.payment.paymentProperties.escrowAccepted,

  userData: state.wallet.userWalletData.userData,
  walletCards: state.wallet.userWalletData.walletCards,
});

export default connect(mapStateToProps)(SelectPaymentMethodPage);
