import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import BackControl from "../../../../components/BackControl";
import { PageView } from "../../../../components/Layout";

import LoadingView from "../../../../components/LoadingView";
import ErrorView from "../../../../components/ErrorView";

import { AppState } from "../../../../../store/RootReducer";
import { PaymentParams } from "../../../../payment/presentation/store/paymentProperties/types";
import { connect, useDispatch } from "react-redux";
import enairaRepository, {
  InitializeRequest,
  InitializeResponse,
} from "../../../domain/repositories/EnairaRepository";
import { triggerShowNotification } from "../../../../components/NotificationWidget/store/actions";
import { triggerInitialize } from "../../store/initialize/actions";
import { TriggerShowNotificationPayload } from "../../../../components/NotificationWidget/store/types";

import { asyncDelay } from "../../../../../core/util/asyncUtil";
import { completeTransaction } from "../../../../payment/presentation/store/paymentStatus/actions";
import { PaymentUtil } from "../../../../payment/util/PaymentUtil";
import { GET_STATUS_SUCCESS } from "../../store/getStatus/types";

const Container = styled(PageView)``;

const LoadingViewContainer = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: white;
`;

const IFrameContainer = styled.div`
  position: relative;
  min-height: 430px;
  display: flex;
`;

interface StoreStateProps {
  paymentParams: PaymentParams;

  initializePending: boolean;
  initializeError: boolean;
  initializeResponse: InitializeResponse | null;
}

interface StoreDispatchProps {
  showNotification: (payload: TriggerShowNotificationPayload) => void;
  completeTransaction: (transactionResponse: any) => void;
  initialize: (request: InitializeRequest) => void;
}

interface OwnProps {}

type Props = StoreStateProps & StoreDispatchProps & OwnProps;

export function RootPage(props: Props) {
  const {
    paymentParams,

    initializePending,
    initializeError,
    initializeResponse,

    showNotification,
    completeTransaction,
    initialize,
  } = props;

  const [isLoadingVisible, setIsLoadingVisible] = useState(true);

  const history = useHistory();

  const isPageMounted = useRef(true);

  const navigateToPaymentMethods = () => {
    history.goBack();
  };

  const dispatch = useDispatch();

  const pollTransactionStatus = async () => {
    while (true) {
      if (!isPageMounted.current) return;

      let getStatusResponse;

      try {
        getStatusResponse = await enairaRepository.getStatus({
          guid: paymentParams.merchantTransactionReference,
        });
      } catch (err) {
        await asyncDelay(2000);
        continue;
      }

      const { responseCode } = getStatusResponse;

      if (PaymentUtil.isTransactionSuccessful(responseCode)) {
        completeTransaction(getStatusResponse);
        break;
      }

      await asyncDelay(2000);
    }
  };

  const callInitialize = () => {
    const {
      merchantCode,
      payableCode,
      paymentId,
      merchantTransactionReference,
    } = paymentParams;

    initialize({
      merchantCode,
      payableCode,
      transactionReference: merchantTransactionReference,
      paymentId,
      currencyCode: paymentParams.currencyCode,
    });
  };

  const iframeOnLoadHandler = () => {
    setIsLoadingVisible(false);
  };

  useEffect(() => {
    return () => {
      isPageMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (initializePending || !!initializeResponse) return;
    callInitialize();
  }, []);

  useEffect(() => {
    if (!initializeResponse) return;
    pollTransactionStatus();
  }, [initializeResponse]);

  useEffect(() => {
    return () => {
      dispatch({
        type: GET_STATUS_SUCCESS,
        payload: { response: null },
      });
    };
  }, [dispatch]);

  
  useEffect(() => {
    if (initializePending && initializeError) {
      // Abort payment
      completeTransaction({
        responseCode: "",
        merchantTransactionReference: paymentParams.merchantTransactionReference
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initializePending, initializeError]);

  return (
    <Container>
      <BackControl text="Back" onClick={navigateToPaymentMethods} />

      {initializePending && <LoadingView />}

      {!initializePending && !!initializeResponse && (
        <IFrameContainer>
          {isLoadingVisible && (
            <LoadingViewContainer>
              <LoadingView />
            </LoadingViewContainer>
          )}

          {initializeResponse && (
            <iframe
              style={{ width: "100%" }}
              onLoad={iframeOnLoadHandler}
              title="Enaira"
              src={initializeResponse.url}
              frameBorder="0"
            />
          )}
        </IFrameContainer>
      )}
    </Container>
  );
}

const mapStateToProps = (state: AppState): StoreStateProps => ({
  paymentParams: state.payment.paymentProperties.paymentParams as PaymentParams,

  initializePending: state.enaira.initialize.initializePending,
  initializeError: state.enaira.initialize.initializeError,
  initializeResponse: state.enaira.initialize.initializeResponse,
});

const mapDispatchToProps = (
  dispatch: (action: any) => void
): StoreDispatchProps => ({
  showNotification(payload: TriggerShowNotificationPayload) {
    dispatch(triggerShowNotification(payload));
  },
  completeTransaction(transactionResponse: any) {
    dispatch(completeTransaction(transactionResponse));
  },
  initialize(request: InitializeRequest) {
    dispatch(triggerInitialize(request));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RootPage);
