import React, { useEffect, useState } from "react";
import { Layout } from "../../components/Layout";
import {
  PayerName,
  BillingReferenceBlock,
  Container,
  CopyButton,
  DetailsWrapper,
  TimeSpan,
  Title,
  Amount,
  Divider,
} from "../styles";
import CopyToClipboard from "react-copy-to-clipboard";
import { useNotifyRevpay } from "../../hooks/useNotifyRevpay";
import { Button } from "../../../../components/Button";
import Loader from "../../../../components/Loader";
import { getFormattedPrice } from "../../../../../util/currencyUtil";
import { getCurrentDateFormatted } from "../../utils";
import { ReactComponent as ClockIcon } from "../../../../../assets/icons/clock.svg";
import { ReactComponent as SuccessIcon } from "../../../../../assets/icons/success-no-fill-variant.svg";
import { ReactComponent as CopyIcon } from "../../../../../assets/icons/copy.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  hideNotification,
  triggerShowNotification,
} from "../../../../components/NotificationWidget/store/actions";
import NotificationWidget from "../../../../components/NotificationWidget";
import { AppState } from "../../../../../store/RootReducer";

export type ReceiptInfo = {
  payer_name: string;
  amount: number;
  ref: string;
  reference: string;
  paymentId: number;
};

export const SuccessView = () => {
  const [textCopied, setTextCopied] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const { handleNotification, loading, data } = useNotifyRevpay();
  const { showNotification, notificationMessage } = useSelector(
    (state: AppState) => state.notificationWidget
  );
  const dispatch = useDispatch();

  const handleCopyToClipboard = () => {
    setTextCopied(true);
  };

  const receipt_info = JSON.parse(
    sessionStorage.getItem("receipt_info") ?? ""
  ) as ReceiptInfo;

  const payerName = receipt_info.payer_name ?? "";
  const ref = receipt_info.ref;
  const amount = receipt_info.amount / 100;
  const formattedAmount = getFormattedPrice(receipt_info.amount, "566");
  const reference = receipt_info.reference;
  const paymentId = receipt_info.paymentId;

  useEffect(() => {
    const notifyPayload = {
      webguid: ref,
      paymentReference: reference,
      amount: amount.toString(),
      paymentId,
    };

    handleNotification(notifyPayload);
  }, [amount, ref, handleNotification, paymentId, reference]);

  const handleDownload = async () => {
    try {
      if (!data?.receiptLink) {
        throw new Error("No receipt link provided");
      }

      setDownloading(true);

      const response = await fetch(data.receiptLink);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const blob = await response.blob();

      const url = window.URL.createObjectURL(blob); // Create a temporary URL

      const a = document.createElement("a");
      a.href = url;
      a.download = "receipt.pdf";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url); // Clean up the URL
      document.body.removeChild(a);
      setDownloading(false);
    } catch (error) {
      setDownloading(false);
      dispatch(
        triggerShowNotification({
          type: "ERROR",
          message: "Error downloading receipt. Please try again later.",
        })
      );
    }
  };

  return (
    <Layout>
      {showNotification && (
        <NotificationWidget
          type={notificationMessage?.type ?? "INFO"}
          message={notificationMessage?.message ?? ""}
          closeHandler={hideNotification}
        />
      )}
      <Container>
        <SuccessIcon
          style={{ width: "80px", height: "80px", marginBottom: "32px" }}
        />
        <Title>Payment Completed!</Title>
        <p style={{ color: "#626D7C", marginBottom: "32px" }}>
          Your payment was successful
        </p>
        <DetailsWrapper>
          <TimeSpan>
            <ClockIcon style={{ marginRight: "8px" }} />{" "}
            {getCurrentDateFormatted()}
          </TimeSpan>
          <PayerName>{payerName}</PayerName>
          <Amount>{formattedAmount} Paid</Amount>
          <Divider width="80%" />
          <BillingReferenceBlock>
            <div>
              <p className="header">Billing reference</p>
              <p className="value">{reference}</p>
            </div>

            {textCopied ? (
              <p>Copied!</p>
            ) : (
              <CopyToClipboard text={reference} onCopy={handleCopyToClipboard}>
                <CopyButton type="button">
                  <CopyIcon />
                  <p style={{ marginLeft: "8px" }}>Copy</p>
                </CopyButton>
              </CopyToClipboard>
            )}
          </BillingReferenceBlock>
        </DetailsWrapper>
        {loading ? (
          <Loader />
        ) : (
          <Button
            type="FLAT"
            color="PRIMARY"
            text="Download receipt"
            loading={downloading}
            onClick={handleDownload}
            containerStyle={{ width: "70%", margin: "10px auto 0" }}
          />
        )}
      </Container>
    </Layout>
  );
};
