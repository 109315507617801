import * as React from 'react';
import styled from 'styled-components';

import Select from './Select';
import { Button } from '../../../../components/Button';
import { GetProvidersResponse } from '../../../domain/repositories/CongoRepository';
import {
	FormControlLabel,
	FormFieldStyles,
	LabelFieldContainer,
} from '../../../../components/Layout';
import usePay from '../hooks/usePay';
import { FormattedMessage } from 'react-intl';
import translate from '../../../../../translations/translate';

interface Props {
	billers: GetProvidersResponse['data'];
}
export default function PaymentForm(props: Readonly<Props>) {
	const { billers } = props;
	const [form, setForm] = React.useState({
		id: '',
		customerId: '',
	});

	const updateValue = (key: string, val: string) => {
		setForm((f) => ({ ...f, [key]: val }));
	};

	const canSubmit = Boolean(form.id && form.customerId);

	const { loading: makingPayment, makePayment } = usePay();

	const onSubmit = () => {
		makePayment(form);
	};

	return (
		<Container>
			<Select
				label={translate('biller', 'Biller')}
				placeholder={translate('selectYourBiller', 'Select your biller...')}
				value={form.id}
				options={billers.map((b) =>
					b.providers.map((biller) => ({
						label: biller.provider,
						value: biller.id,
					}))
				)}
				onChange={(v: any) => updateValue('id', v)}
			/>

			<LabelFieldContainer className="form-control">
				<FormControlLabel>
				<FormattedMessage id="mobileNoWalletId" defaultMessage="Mobile Number or Wallet ID" />
				</FormControlLabel>

				<InputField
					inputMode="tel"
					name="customerId"
					placeholder="902345678"
					value={form.customerId}
					onChange={(e) => updateValue('customerId', e.target.value)}
				/>
			</LabelFieldContainer>

			<Button
				text={translate('pay', 'Pay')}
				color="PRIMARY"
				className="submit"
				disabled={!canSubmit}
				loading={makingPayment}
				onClick={onSubmit}
			/>
		</Container>
	);
}

const Container = styled.div`
	padding-top: 20px;
	display: flex;
	flex-direction: column;

	.loader-line {
		margin-top: 16px;
		display: flex;
		font-size: 1.2rem;
		color: rgba(0, 0, 0, 0.5);
		span {
			margin-left: 6px;
		}
	}

	.form-control {
		margin-top: 20px;
		margin-bottom: 0;
	}

	.submit {
		width: 100%;
		margin-top: 20px;
	}
`;

export const InputField = styled.input`
	${FormFieldStyles};
`;
