import { createIntl, createIntlCache } from '@formatjs/intl';
import { messages } from './messages';
const cache = createIntlCache();
let intl = createIntl(
	{
		locale: 'en',
		messages: messages['en'],
	},
	cache
);
export function changeLanguage(lang: string) {
	const newIntl = createIntl(
		{
			locale: lang,
			messages: messages[lang],
		},
		cache
	);
	intl = newIntl;
}
const translate = (id: string, defaultMessage: string, values?: {}) =>
	intl.formatMessage({ id, defaultMessage }, values);
export default translate;
