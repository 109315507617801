import React, { useState, useCallback } from 'react';
import { AxiosError } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { getAxiosErrorData } from '../../../../../core/api/helpers';
import useIsMountedRef from '../../../../../hooks/useIsMountedRef';
import { triggerShowNotification } from '../../../../components/NotificationWidget/store/actions';
import RequestState from '../../../../payment/domain/RequestState';
import {
	PaymentResponse,
	postPayment,
} from '../../../domain/repositories/CongoRepository';
import { AppState } from '../../../../../store/RootReducer';
import { useHistory } from 'react-router-dom';
import { PAYMENT__DRC_MOBILE_MONEY_USSD } from '../../../../Routes';
import { ssGet } from '../utils/sessionStorage';
import { completeTransaction } from '../../../../payment/presentation/store/paymentStatus/actions';

export default function usePay() {
	const history = useHistory();

	const { paymentParams } = useSelector((state: AppState) => ({
		paymentParams: state.payment.paymentProperties.paymentParams,
	}));

	const [req, setReq] = useState<RequestState<PaymentResponse>>({
		loading: false,
	});

	const isMounted = useIsMountedRef().current;

	const dispatch = useDispatch();

	const message = 'Unable to initiate payment. Please try again';

	const handleError = useCallback(
		(error: any) => {
			if (!isMounted) return;

			setReq({
				loading: false,
				error: String(getAxiosErrorData(error as AxiosError)) || message,
			});

			dispatch(triggerShowNotification({ type: 'ERROR' }));
		},
		[dispatch, isMounted]
	);

	const paymentIdValue = (payParams: any) => {
		return process.env.NODE_ENV === 'test' ||
			process.env.NODE_ENV === 'development'
			? '3512224'
			: payParams.paymentId.toString();
	};

	const currencyChange = (payParams: any) => {
		if (payParams.currencyCode === '840') return '840';

		return 'CDF';
	};

	const makePayment = useCallback(
		async (p: { id: string; customerId: string }) => {
			if (!paymentParams) return;
			setReq({ loading: true });
			let billerName = ssGet('selectedBiller');

			const addedParam = (billerName === 'AIRTELMONEY' ||
				billerName === 'MPESA') && { merchantCountry: 'CD' };

			const paymentObject: any = {
				merchantCode: paymentParams.merchantCode,
				payableCode: paymentParams.payableCode,
				transactionReference: paymentParams.merchantTransactionReference,
				paymentId: paymentIdValue(paymentParams),
				orderId: Date.now().toString(),
				orderAmount: paymentParams.amount,
				orderCurrency: currencyChange(paymentParams),
				customerMobileNumber: `+243${p.customerId.toUpperCase()}`,
				mobileWalletProvider: billerName,
				merchantId: p.id,
				transType: paymentParams.transactionType,
				...addedParam,
			};

			try {
				const res = await postPayment(paymentObject);

				if (isMounted) {
					const responseCode = res.data.code;
					console.log('responseCode', responseCode);

					switch (responseCode) {
						case '00':
							history.push(PAYMENT__DRC_MOBILE_MONEY_USSD);
							break;
						case '02':
							dispatch(completeTransaction({ responseCode: res.data.code }));
							break;
						default:
							dispatch(completeTransaction(res.data));
							break;
					}
					// setReq({ loading: false, data: res.data });
				}
			} catch (error) {
				// Abort payment
				dispatch(
          completeTransaction({
            responseCode: "",
            merchantTransactionReference: paymentObject.transactionReference,
          })
        );
			 
				handleError(error);
			}
		},
		[paymentParams, isMounted, history, handleError]
	);
	return { makePayment, ...req };
}
