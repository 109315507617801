import React from "react";
import styled from "styled-components";
import { ReactComponent as QuestionIcon } from "../../../../../assets/icons/question.svg";

export const Header = () => {
  return (
    <Wrapper>
      <SupportText>
        <QuestionIcon width="20px" style={{ marginRight: "12px" }} />
        Need help? <span>Contact support</span>
      </SupportText>
    </Wrapper>
  );
};

const Wrapper = styled("div")`
  display: flex;
  align-items: center;
  padding: 20px 60px;
  border-bottom: 1px solid #e0e0e0;
`;

const SupportText = styled("p")`
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #626d7c;
  margin-left: auto;
  span {
    color: #009fe4;
    font-weight: 500;
    margin-left: 4px;
  }
`;
