import React from "react";
import { Layout } from "../components/Layout";
import { Container, Title } from "./styles";
import { ReactComponent as SuccessIcon } from "../../../../assets/icons/success-no-fill-variant.svg";

export const PaymentCompletedView = () => {
  return (
    <Layout>
      <Container>
        <SuccessIcon
          style={{ width: "80px", height: "80px", marginBottom: "32px" }}
        />
        <Title>Payment Completed!</Title>
        <p
          style={{
            color: "#626D7C",
            marginBottom: "32px",
            textAlign: "center",
          }}
        >
          The amount due for the GUID has been fully paid. No futher payments
          required. Thank you!
        </p>
      </Container>
    </Layout>
  );
};
