import React from "react";
import styled from "styled-components";
import Loader from "../../../components/Loader";

interface LoaderContainerProps {
  error: boolean;
}

export const LoaderContainer = ({ error }: LoaderContainerProps) => {
  return (
    <Container>
      {error ? (
        <Message>
          Something went wrong validating your GUID. Please ensure it's correct
          and try again.
        </Message>
      ) : (
        <Loader />
      )}
    </Container>
  );
};

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  background-color: #0000004d;
  justify-content: center;
  align-items: center;
`;

const Message = styled("p")`
  font-size: 2.2rem;
  color: #fff;
`;
