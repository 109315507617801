import React, { ReactNode } from "react";
import { ReactComponent as CircleCaution } from "../../assets/icons/circle-caution.svg";
import styled from "styled-components";
import MethodItem from "./MethodItem";
import OPayIconPath from "../payment/presentation/pages/SelectPaymentMethodPage/icons/opay.svg";
import usePaymentChannelHelper from "../../hooks/usePaymentChannelHelper";
import useOnMethodSelect from "../../hooks/useOnMethodSelect";
import { PaymentChannelType, PaymentResponseFeedback } from "../payment/presentation/store/paymentProperties/types";
import RetryButton from "./RetryButton";


const Container = styled.div`
  .failed-payment-info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .failed-payment-description {
    text-align: center;
    margin: 15px 0;
    font-size: 1.5rem;
    color: #181b24;
  }

  .retry-btn p {
    font-weight: 600;
  }

  .failed-payment-divider {
    display: flex;
    align-items: center;
    margin-top: 30px;

    hr {
      width: 5%;
      border: 0;
      height: 1px;
      background-color: #dddddd;
    }

    &-text {
      font-size: 14px;
    }
  }

  .alternate-channels {
    max-height: 45vh;
    overflow-y: auto;
    margin-top: 15px;

    ::-webkit-scrollbar {
      width: 6px; /* width of the entire scrollbar */
    }

    ::-webkit-scrollbar-track {
      /* background: orange; color of the tracking area */
    }

    ::-webkit-scrollbar-thumb {
      background-color: #dddddd;
      border-radius: 20px;
      /* border: 3px solid orange; */
    }
  }
`;

interface Props {
  icon?: ReactNode;
  title?: string;
  transactionStatus: string;
  paymentResponseFeedback: PaymentResponseFeedback;
}

const FailedPayment = (props: Props) => {
  const {
    icon,
    title,
    transactionStatus,
    paymentResponseFeedback
  } = props;

  const { enabledPaymentMinusCurrentData, currentPaymentChannel } =
    usePaymentChannelHelper();
  const onMethodSelect = useOnMethodSelect();

  const isTransactionExpired = transactionStatus === "EXPIRED";
  const isTransactionCancelled = transactionStatus === "CANCELLED";

  const hideAlternativeMethods = isTransactionExpired || isTransactionCancelled;

  return (
    <Container className="failed-payment">
      <div className="failed-payment-info">
        {icon || <CircleCaution />}

        <h1 className="failed-payment-title">
          {title ?? "Transaction Failed"}
        </h1>

        {paymentResponseFeedback.description && (
          <div className="failed-payment-description" dangerouslySetInnerHTML={{ __html : paymentResponseFeedback.description }}></div> 

        )}

        {!hideAlternativeMethods && (
          <RetryButton
            paymentChannel={currentPaymentChannel as PaymentChannelType}
          />
        )}
      </div>

      {!hideAlternativeMethods && (
        <>
          <div className="failed-payment-divider">
            <hr />
            <div className="failed-payment-divider-text">
              or select an alternative payment method
            </div>
            <hr />
          </div>
          <div className="alternate-channels">
            {enabledPaymentMinusCurrentData.map((method) => {
              const { type, title, imageIcon, description, plainIcon } = method;

              return (
                <MethodItem
                  key={type}
                  type={type}
                  title={title}
                  description={description}
                  imageIcon={type !== "OPAY" ? imageIcon : undefined}
                  iconPath={type === "OPAY" ? OPayIconPath : undefined}
                  plainIcon={plainIcon}
                  onSelect={() => onMethodSelect(type)}
                />
              );
            })}
          </div>
        </>
      )}
    </Container>
  );
};

export default FailedPayment;
