import styled, { css, keyframes } from "styled-components";
import { ScreenSize } from "../../styling/constants";
import { AppDisplayMode } from "../payment/presentation/store/paymentProperties/types";

const WIDGET_MAX_WIDTH = 420;

const popupAnimation = keyframes`
  0% { 
    opacity: 0;     
    transform: scale(0.98) translateY(20px);
  }
	100% {
    opacity: 1;     
    transform: scale(1) translateY(0px);
  }
`;

const popupAnimationDesktop = keyframes`
  0% { 
    opacity: 0;     
    transform: scale(0.8) translateY(50px);
  }
	100% {
    opacity: 1;     
    transform: scale(1) translateY(0px);
  }
`;

interface ContainerProps {
  displayMode: AppDisplayMode;
}

export const Container = styled.div<ContainerProps>`
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  overflow: auto;

  ${({ displayMode }) =>
    displayMode === "INLINE" &&
    css`
      animation: ${popupAnimation} 0.4s;
    `};

  @media (min-width: ${ScreenSize.XS}px) {
    background-color: #f3f4f5;
    padding-top: 30px;
    box-sizing: border-box;

    ${({ displayMode }) =>
      displayMode === "INLINE" &&
      css`
        background-color: transparent !important;
        animation: ${popupAnimationDesktop} 0.4s ease-out;
      `};
  }
`;

interface CancelHeaderContainerProps {
  displayMode: AppDisplayMode;
}

export const CancelHeaderContainer = styled.div<CancelHeaderContainerProps>`
  display: none;
  width: 100%;

  div {
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
  }

  p {
    font-size: 1.2rem;
    color: #4a4a4a;
    cursor: pointer;
  }

  svg {
    width: auto;
    height: 10px;
    margin-right: 7px;
    fill: #a9a9a9;
    cursor: pointer;
  }

  ${({ displayMode }) =>
    displayMode === "INLINE" &&
    css`
      p {
        color: #e6e6e6;
      }

      svg {
        fill: #e6e6e6;
      }
    `};

  @media (min-width: ${ScreenSize.XS}px) {
    display: flex;
    max-width: ${WIDGET_MAX_WIDTH}px;
  }
`;

interface PaymentWidgetContainerProps {
  displayMode: AppDisplayMode;
}

export const PaymentWidgetContainer = styled.div<PaymentWidgetContainerProps>`
  width: 100%;
  height: fit-content;
  position: relative;
  background-color: white;
  border-radius: 3px;

  @media (min-width: ${ScreenSize.XS}px) {
    max-width: ${WIDGET_MAX_WIDTH}px;
    box-shadow: 0 3.5px 4.5px -1.5px rgba(70, 70, 70, 0.06);
  }
`;

export const ActivePageContainer = styled.div`
  min-height: 400px;
  display: flex;
  flex-direction: column;
`;

interface BrandFooterProps {
  displayMode: AppDisplayMode;
}

export const FooterContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  @media (min-width: ${ScreenSize.XS}px) {
    flex: 0;
  }
`;

export const BrandContainer = styled.div<BrandFooterProps>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  margin-top: 15px;
  margin-bottom: 25px;

  p {
    font-size: 1.1rem;
    margin-right: 4px;
  }

  img {
    height: 20px;
    object-fit: contain;
    margin-bottom: 1.5px;
    transform: translateZ(0px);
  }

  @media (min-width: ${ScreenSize.XS}px) {
    flex: 0;

    ${({ displayMode }) =>
      displayMode === "INLINE" &&
      css`
        opacity: 0.8;

        p {
          color: white;
        }

        img {
          filter: brightness(0) invert(1);
        }
      `}
  }
`;

interface HeaderWrapperProps {
  acquiredBy: string;
}
export const TopHeaderWrapper = styled.div<HeaderWrapperProps>`
${({ acquiredBy }) =>
  acquiredBy === "MUL" &&
  css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px 0 0;
  `};
`;

export const TranslationSwitch = styled.div`
  margin-left: 10px;
`;
